import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MediaService } from '@studiobuki/web-core/lib/media';

@Component({
  selector: 'app-banner-one',
  templateUrl: './banner-one.component.html',
  styleUrls: ['./banner-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerOneComponent {
  constructor(public mediaService: MediaService) {}
}
