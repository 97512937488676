import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { InputModule } from '@studiobuki/web-core/lib/input';
import { CalculatorModule } from '@studiobuki/web-core/lib/calculator';
import { ImageTitleModule } from '@studiobuki/web-core/lib/image-title';
import { SectionMessageDateModule } from '@studiobuki/web-core/lib/section-message-date';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { ProductRadioCardModule } from '@studiobuki/web-core/lib/product-radio-card';
import { DeleteProductDialogComponent } from './components/delete-product-dialog/delete-product-dialog.component';
import { SelectOptionModule } from './components/select-option/select-option.module';
import { ProductModule } from './components/product/product.module';
import { PaymentsComponent } from './components/payments/payments.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { SelectComponent } from './components/select/select.component';
import { SectionCartComponent } from './components/section-cart/section-cart.component';
import { CartPageComponent } from './cart-page.component';

@NgModule({
  declarations: [
    CartPageComponent,
    SectionCartComponent,
    SelectComponent,
    CouponComponent,
    PaymentsComponent,
    DeleteProductDialogComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ProductModule,
    SelectOptionModule,
    RouterModule,
    InputModule,
    CalculatorModule,
    ImageTitleModule,
    SectionHeaderGapModule,
    SectionMessageDateModule,
    ButtonModule,
    ProductRadioCardModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class CartPageModule {}
