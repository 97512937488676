<div
  style="min-height: 100vh; flex-direction: column"
  [ngStyle]="{
    overflow: overflow,
    marginTop: (headerService.topbarHeight$ | async) + 'px'
  }"
>
  <app-header
    class="app-header"
    *ngIf="LayoutHeader === LAYOUT_HEADER.shown"
  ></app-header>
  <div
    fxFlex
    class="app-content"
  >
    <div
      fxLayout="column"
      fxFlexFill
    >
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
      <div
        *ngIf="LayoutFooter === LAYOUT_FOOTER.shown"
        class="footer-text"
      >
        ©️やなせたかし
      </div>
      <lib-section-footer
        *ngIf="LayoutFooter === LAYOUT_FOOTER.shown"
        [footerMainMenu]="footerMainMenu"
        [footerSideMenu]="footerSideMenu"
      >
        <a
          routerLink="/"
          class="logo"
        ></a>
        <a
          href="https://www.froebel-kan.co.jp/"
          target="_blank"
          class="logo logo_alt"
        ></a>
      </lib-section-footer>
    </div>
  </div>
</div>

<lib-loader></lib-loader>
