import { Component } from '@angular/core';
import type { IArticle } from '@studiobuki/web-core/lib/section-text';

// const contactsHref = `${BASE_HREF}${ROUTE_PATH_CONTACTS}`;

@Component({
  templateUrl: './ordercontract-gift-page.component.html',
  styleUrls: ['./ordercontract-gift-page.component.scss'],
})
export class OrdercontractGiftPageComponent {
  public readonly articles: IArticle[] = [
    {
      caption: 'ギフトコード　資金決済法に基づく表示',
      passages: [
        {
          items: [
            {
              type: 'text',
              text: /* html */ `前払式支払手段　情報提供事項
              `,
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: /* html */ `発行業者：<br><a href="https://hellobuki.com/jp/" >STUDIO BUKI株式会社`,
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: /* html */ `支払可能金額等：<br>購入したギフトコードに対応する商品にのみご利用いただけます。`,
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: /* html */ `有効期間：<br>当社が発行するギフトコードを記載したカードは、有効期間を2年間を超えない期間とし、有効期間を過ぎた時点で失効します。<br>当社が発行するギフトコードを記載したカード以外は、有効期間を1年間とし、有効期間を過ぎた時点で失効します。
`,
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: /* html */ `利用範囲等：<br>ギフトコードは当サイト内でのみでご利用できます。`,
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: /* html */ `利用規約：<br>当社の利用規約をご確認ください。`,
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: /* html */ `お問い合わせ先：<br>カスタマーサポート 080-7652-1468（平日10~16時、祝日・連休・年末年始除く）`,
            },
          ],
        },
      ],
    },
  ];
}
