export const KEY_KIDS_TITLE = '{{kidsTitle}}';
export const KEY_KIDS_TITLE_VERTICAL = '{{kidsTitleVertical}}';
export const KEY_KIDS_TITLE_INDEX = '{{kidstitleIndex}}';
export const KEY_KIDS_TITLE_SEGMENT_26 = '{{kidstitleSegment26}}';

export const KEY_FIRST_SYMBOL = '{{firstSymbol}}';

export const KEY_FIRST_SYMBOL_CHARACTER = '{{firstSymbolCharacter}}';

export const KEY_SECOND_SYMBOL = '{{secondSymbol}}';

export const KEY_SECOND_SYMBOL_CHARACTER = '{{secondSymbolCharacter}}';

export const KEY_WITH_SYMBOL = '{{withSymbol}}';

export const KEY_WITHOUT_SYMBOL = '{{withoutSymbol}}';
