import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormHeaderModule } from '@studiobuki/web-core/lib/form-header';
import { InputModule } from '@studiobuki/web-core/lib/input';
import { FormGiftSpecialComponent } from './form-gift-special.component';

@NgModule({
  declarations: [FormGiftSpecialComponent],
  imports: [CommonModule, FormHeaderModule, InputModule],
  exports: [FormGiftSpecialComponent],
})
export class FormGiftSpecialModule {}
