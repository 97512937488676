import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { IStep } from 'src/app/pages/order-page/interfaces';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsComponent {
  @Input() steps!: IStep[];

  @Input() step!: IStep['id'];

  // constructor() {}
}
