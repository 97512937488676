import type { Route } from '@angular/router';
import {
  ELayoutFooter,
  ELayoutHeader,
  ROUTE_PATH_ABOUT,
  ROUTE_PATH_AUTH,
  ROUTE_PATH_BOOK,
  ROUTE_PATH_BOOK_FROEBELKAN,
  ROUTE_PATH_CART,
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_COVER,
  ROUTE_PATH_FAQ,
  ROUTE_PATH_GIFT_BUY,
  ROUTE_PATH_GIFT_BUY_SPECIAL,
  ROUTE_PATH_GIFT_ORDER,
  ROUTE_PATH_GIFT_THANKS,
  ROUTE_PATH_GIFT_USE,
  ROUTE_PATH_GIFT_USE_NO_LOGO,
  ROUTE_PATH_ORDER,
  ROUTE_PATH_ORDERCONTRACT,
  ROUTE_PATH_ORDERCONTRACT_GIFT,
  ROUTE_PATH_PARAM_AUTH_TYPE,
  ROUTE_PATH_PARAM_GIFT_ID,
  ROUTE_PATH_PARAM_ID,
  ROUTE_PATH_PARAM_ORDER_ID,
  ROUTE_PATH_POLICY,
  ROUTE_PATH_REVIEWS,
  ROUTE_PATH_TERMS,
} from '@studiobuki/web-core/lib/routing';
import { ROUTE_PATH_404, ROUTE_PATH_HOME } from './app-routing.data';
import { BookPageComponent } from './pages/book-page/book-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';
import { CoverPageComponent } from './pages/cover-page/cover-page.component';
import { CartPageComponent } from './pages/cart-page/cart-page.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { OrdercontractPageComponent } from './pages/ordercontract-page/ordercontract-page.component';
import { GiftBuyPageComponent } from './pages/gift-buy-page/gift-buy-page.component';
import { GiftBuySpecialPageComponent } from './pages/gift-buy-special-page/gift-buy-special-page.component';
import { GiftOrderPageComponent } from './pages/gift-order-page/gift-order-page.component';
import { GiftOrderPageResolver } from './pages/gift-order-page/gift-order-page.resolver';
import { GiftThanksPageComponent } from './pages/gift-thanks-page/gift-thanks-page.component';
import { GiftUsePageComponent } from './pages/gift-use-page/gift-use-page.component';
import { ReviewsPageComponent } from './pages/reviews-page/reviews-page.component';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { AuthPageGuard } from './pages/auth-page/auth-page.guard';
import { OrdercontractGiftPageComponent } from './pages/ordercontract-gift-page/ordercontract-gift-page.component';

const MAKE_ROUTE = <T extends Route>(r: T) => r;

export const ROUTE_HOME = MAKE_ROUTE({
  path: ROUTE_PATH_HOME,
  component: HomePageComponent,
} as const);

export const ROUTE_ABOUT = MAKE_ROUTE({
  path: ROUTE_PATH_ABOUT,
  component: AboutPageComponent,
} as const);

export const ROUTE_TERMS = MAKE_ROUTE({
  path: ROUTE_PATH_TERMS,
  component: TermsPageComponent,
} as const);

export const ROUTE_POLICY = MAKE_ROUTE({
  path: ROUTE_PATH_POLICY,
  component: PolicyPageComponent,
} as const);

export const ROUTE_ORDERCONTRACT = MAKE_ROUTE({
  path: ROUTE_PATH_ORDERCONTRACT,
  component: OrdercontractPageComponent,
} as const);

export const ROUTE_ORDERCONTRACT_GIFT = MAKE_ROUTE({
  path: ROUTE_PATH_ORDERCONTRACT_GIFT,
  component: OrdercontractGiftPageComponent,
} as const);

export const ROUTE_FAQ = MAKE_ROUTE({
  path: ROUTE_PATH_FAQ,
  component: FaqPageComponent,
} as const);

export const ROUTE_CONTACTS = MAKE_ROUTE({
  path: ROUTE_PATH_CONTACTS,
  component: ContactsPageComponent,
} as const);

export const ROUTE_CART = {
  path: ROUTE_PATH_CART,
  component: CartPageComponent,
} as const;

export const ROUTE_ORDER = {
  path: ROUTE_PATH_ORDER,
  component: OrderPageComponent,
  data: {
    LayoutHeader: ELayoutHeader.hidden,
    LayoutFooter: ELayoutFooter.hidden,
  },
} as const;

export const ROUTE_GIFT_BUY: Route = {
  path: ROUTE_PATH_GIFT_BUY,
  component: GiftBuyPageComponent,
};

export const ROUTE_GIFT_BUY_SPECIAL: Route = {
  path: ROUTE_PATH_GIFT_BUY_SPECIAL,
  component: GiftBuySpecialPageComponent,
};

export const ROUTE_GIFT_USE: Route = {
  path: ROUTE_PATH_GIFT_USE,
  component: GiftUsePageComponent,
};

export const ROUTE_GIFT_USE_WITH_GIFT_ID: Route = {
  ...ROUTE_GIFT_USE,
  path: `${ROUTE_PATH_GIFT_USE}/:${ROUTE_PATH_PARAM_GIFT_ID}`,
};

export const ROUTE_GIFT_USE_NO_LOGO: Route = {
  path: ROUTE_PATH_GIFT_USE_NO_LOGO,
  component: GiftUsePageComponent,
  data: {
    LayoutHeader: ELayoutHeader.hidden,
    LayoutFooter: ELayoutFooter.hidden,
  },
};

export const ROUTE_GIFT_USE_NO_LOGO_WITH_GIFT_ID: Route = {
  ...ROUTE_GIFT_USE_NO_LOGO,
  path: `${ROUTE_PATH_GIFT_USE_NO_LOGO}/:${ROUTE_PATH_PARAM_GIFT_ID}`,
};

export const ROUTE_GIFT_THANKS: Route = {
  path: ROUTE_PATH_GIFT_THANKS,
  component: GiftThanksPageComponent,
  data: {
    title: 'ギフトコードで絵本を贈る ',
    meta_title: 'BÜKI 物語の主人公になれる絵本（ブーキー）',
    LayoutHeader: ELayoutHeader.hidden,
    LayoutFooter: ELayoutFooter.hidden,
  },
};

export const ROUTE_GIFT_ORDER: Route = {
  path: ROUTE_PATH_GIFT_ORDER,
  component: GiftOrderPageComponent,
  data: {
    LayoutHeader: ELayoutHeader.hidden,
    LayoutFooter: ELayoutFooter.hidden,
  },
  resolve: {
    resolved: GiftOrderPageResolver,
  },
};

export const ROUTE_GIFT_ORDER_WITH_ORDER_ID: Route = {
  ...ROUTE_GIFT_ORDER,
  path: `${ROUTE_PATH_GIFT_ORDER}/:${ROUTE_PATH_PARAM_ORDER_ID}`,
};

export const ROUTE_404 = MAKE_ROUTE({
  path: ROUTE_PATH_404,
  component: NotFoundPageComponent,
  data: {
    LayoutFooter: ELayoutFooter.hidden,
  },
} as const);

export const ROUTE_BOOK = MAKE_ROUTE({
  path: ROUTE_PATH_BOOK,
  component: BookPageComponent,
} as const);

export const ROUTE_BOOK_FROEBELKAN = MAKE_ROUTE({
  path: ROUTE_PATH_BOOK_FROEBELKAN,
  loadChildren: () =>
    import(
      './pages/book-page/pages/book-froebelkan-page/book-froebelkan-page.module'
    ).then((m) => m.BookFroebelkanPageModule),
} as const);

export const ROUTE_COVER: Route = {
  path: `${ROUTE_PATH_COVER}/:${ROUTE_PATH_PARAM_ID}`,
  component: CoverPageComponent,
};

export const ROUTE_AUTH: Route = {
  path: `${ROUTE_PATH_AUTH}`,
  component: AuthPageComponent,
  canActivate: [AuthPageGuard],
};

export const ROUTE_AUTH_WITH_TYPE: Route = {
  path: `${ROUTE_PATH_AUTH}/:${ROUTE_PATH_PARAM_AUTH_TYPE}`,
  component: AuthPageComponent,
  canActivate: [AuthPageGuard],
};

export const ROUTE_REVIEWS: Route = {
  path: `${ROUTE_PATH_REVIEWS}`,
  component: ReviewsPageComponent,
};
