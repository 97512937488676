import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';

import { SectionGiftBooksModule } from 'src/app/shared/section-gift-books/section-gift-books.module';
import { SectionGiftCheckoutModule } from 'src/app/shared/section-gift-checkout/section-gift-checkout.module';
import { ImageTitleModule } from '@studiobuki/web-core/lib/image-title';
import { SectionStepsModule } from '@studiobuki/web-core/lib/section-steps';
import { SectionFaqModule } from '@studiobuki/web-core/lib/section-faq';
import { SectionGiftHeaderComponent } from './components/section-gift-header/section-gift-header.component';
import { SectionGiftCartModule } from '../../shared/section-gift-cart/section-gift-cart.module';
import { SectionGiftFaqModule } from '../../shared/section-gift-faq/section-gift-faq.module';
import { SectionGiftUseCasesModule } from '../../shared/section-gift-use-cases/section-gift-use-cases.module';
import { GiftBuyPageComponent } from './gift-buy-page.component';

@NgModule({
  declarations: [GiftBuyPageComponent, SectionGiftHeaderComponent],
  imports: [
    CommonModule,
    ImageTitleModule,
    SectionHeaderGapModule,
    SectionGiftUseCasesModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionGiftBooksModule,
    SectionGiftCartModule,
    SectionGiftFaqModule,
    SectionGiftCheckoutModule,
  ],
})
export class GiftBuyPageModule {}
