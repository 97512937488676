<div
  class="content"
  [formGroup]="formGroup"
>
  <div class="content__input">
    <lib-input
      name="email"
      [label]="'Email Address'"
      placeholder="sample@hellobuki.com"
      formControlName="email"
      type="email"
      [error]="EMAIL_ERROR"
      [theme]="EInputTheme.alt"
    ></lib-input>
  </div>
  <div class="content__button">
    <lib-button
      (buttonClick)="onResetButtonClick()"
      [fullWidth]="true"
      [disabled]="formGroup.invalid"
      >{{ 'auth.reset.button' }}</lib-button
    >
  </div>
  <div class="content__footer">
    <p class="footer-text">
      Go back <a (click)="onSignInButtonClick()">to login</a>.
    </p>
  </div>
</div>
