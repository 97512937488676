<div class="container">
  <div class="container__caption">
    <h4
      class="caption"
      [innerHTML]="caption | safe: 'html'"
    ></h4>
  </div>
  <div class="container__photo">
    <img
      [src]="photo"
      alt="#"
      class="photo"
    />
  </div>
  <div class="container__summary">
    <div
      *ngFor="let s of summary"
      class="summary"
    >
      <p class="summary__item">{{ s }}</p>
    </div>
  </div>
  <!-- <div class="container__button">
    <lib-button
      color="accent-alt"
      size="xs"
      (buttonClick)="onButtonClick()"
    >この絵本をつくる</lib-button>
  </div> -->
</div>
