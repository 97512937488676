<div class="container">
  <div class="container__section">
    <button
      class="item"
      [class.item_active]="(active$ | async) === true"
      [disabled]="(active$ | async) === true"
      (click)="enable()"
    >
      <span class="item__icon"></span>
      <span class="item__text">お届け先と同じ</span>
    </button>
  </div>
  <div class="container__section">
    <button
      class="item"
      [class.item_active]="(active$ | async) === false"
      [disabled]="(active$ | async) === false"
      (click)="disable()"
    >
      <span class="item__icon"></span>
      <span class="item__text">お届け先と別の情報を入力</span>
    </button>
  </div>
</div>
