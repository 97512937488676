<ng-template #content>
  <div
    *ngIf="label"
    class="navigation-item__label"
  >
    {{ label }}
  </div>
  <svg-icon
    *ngIf="svgIconSrc"
    class="navigation-item__svg-icon"
    [src]="svgIconSrc"
    [applyClass]="true"
  ></svg-icon>
  <span
    *ngIf="title"
    class="navigation-item__text"
    >{{ title }}</span
  >
  <i
    *ngIf="children.length"
    class="navigation-item__dropdown-icon"
  ></i>
  <app-navigation-dropdown
    *ngIf="(isDropdownActive | async) && children.length"
    @fade
  >
    <app-navigation-dropdown-item
      *ngFor="let child of children"
      [imgSrc]="child.imgSrc"
      [link]="child.link"
      >{{ child.title }}</app-navigation-dropdown-item
    >
  </app-navigation-dropdown>
</ng-template>

<a
  *ngIf="link"
  class="navigation-item"
  [routerLink]="link.split('#')[0]"
  [fragment]="$any(link.split('#')[1])"
  [target]="target"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a
  *ngIf="href"
  class="navigation-item"
  [href]="href"
  [target]="target"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<span
  *ngIf="!href && !link"
  class="navigation-item"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>
