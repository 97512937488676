<div
  class="container"
  [ngClass]="{
    container_final: isCompleted
  }"
>
  <app-section-full
    class="container__section"
    *ngIf="isCompleted"
  >
    <ng-container *ngTemplateOutlet="sectionLeft"></ng-container>
  </app-section-full>
  <app-section-left
    class="container__section container__section_left"
    *ngIf="!isCompleted"
  >
    <ng-container *ngTemplateOutlet="sectionLeft"></ng-container>
  </app-section-left>
  <app-section-right
    class="container__section container__section_right"
    *ngIf="!isCompleted && (order$ | async) as order"
  >
    <app-aside
      [books]="order.books"
      [shipping]="(shipping$ | async) || undefined"
      [coupon]="(coupon$ | async) || undefined"
    ></app-aside>
  </app-section-right>
  <app-header
    class="container__section container__section_header header"
    [step]="step"
    *ngIf="media.isActive('lt-xl')"
  ></app-header>
</div>

<ng-template #sectionLeft>
  <app-header
    class="header"
    [step]="step"
    [steps]="[
      {
        id: 1,
        name: 'お客さま情報入力'
      },
      {
        id: 2,
        name: '配送方法選択'
      },
      {
        id: 3,
        name: 'お支払い'
      }
    ]"
    *ngIf="media.isActive('gt-lg')"
  ></app-header>
  <app-main
    *ngIf="!isCompleted && (shippings$ | async) as shippings"
    class="main"
    [(step)]="step"
    (dataChange)="onChange($event)"
    [disabledShippings]="disabledShippingMethods"
    [shippings]="shippings"
    [shipping]="(shipping$ | async) || undefined"
    (backClick)="backClick()"
    (nextClick)="nextClick()"
    [isBackDisabled]="isBackDisabled"
    [isNextDisabled]="isNextDisabled"
  ></app-main>
  <app-final
    class="main"
    *ngIf="isCompleted"
    [barOrderId]="(order$ | async)?.barOrderId"
  ></app-final>
  <app-footer class="footer"></app-footer>
</ng-template>
