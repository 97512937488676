import { Injectable } from '@angular/core';
import {
  INPUT_VALUE_STEP_AGE_TO_BE_BIRTH_TIME,
  INPUT_VALUE_STEP_CHARACTER,
  INPUT_VALUE_STEP_COVER_IMAGE,
  INPUT_VALUE_STEP_MESSAGE,
  INPUT_VALUE_STEP_PHOTO,
  INPUT_VALUE_STEP_WHOS_CELEBRATING,
} from '@shared/common/book/constants';
import {
  INPUT_VALUE_CHARACTERS_C01,
  INPUT_VALUE_CHARACTERS_C02,
  INPUT_VALUE_CHARACTERS_C03,
  INPUT_VALUE_CHARACTERS_C04,
  INPUT_VALUE_CHARACTERS_C05,
  INPUT_VALUE_CHARACTERS_C06,
  PHOTO_FROEBELKAN,
  PHOTO_FROEBELKAN_2,
  PHOTO_FROEBELKAN_3,
  PHOTO_FROEBELKAN_4,
  PHOTO_FROEBELKAN_5,
} from '@shared/jp/book/constants';
import type { IBookFroebelkanData } from '@shared/jp/models';
import { BookService } from '@studiobuki/web-core/lib/book-common';
import type { TCreateUserBookOmitKeys } from '@studiobuki/web-core/lib/firebase';
import { FirebaseService } from 'src/app/services/firebase.service';
import type { TBookMode } from '@studiobuki/web-core/lib/routing';
import { ROUTE_PATH_QUERY_PARAM_MODE_USER } from '@studiobuki/web-core/lib/routing';
import Froebelkan from 'src/app/data/books/Froebelkan';
import { Logger } from '@shared/common/logger';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import { BookPagesFroebelkanService } from './book-pages-froebelkan.service';
import { getKidstitle } from './segments-utils';

type TBookFroebelkanCreateData = Pick<
  IBookFroebelkanData,
  'heroName' | 'kunChan' | 'birthDate' | 'ageToBe'
>;

const log = new Logger('BookFroebelkanService');

@Injectable({
  providedIn: 'root',
})
export class BookFroebelkanService extends BookService<
  IBookFroebelkanData,
  TBookFroebelkanCreateData
> {
  constructor(
    protected override _firebaseService: FirebaseService,
    protected override _bookPagesService: BookPagesFroebelkanService,
    private _loaderService: LoaderService,
  ) {
    super(_firebaseService, _bookPagesService);
  }

  /** a method to create initial book data */
  // eslint-disable-next-line class-methods-use-this
  protected override _createBookData<O extends Partial<IBookFroebelkanData>>(
    /** data to create the book from */
    createBookData: TBookFroebelkanCreateData,
    /** book data to owerride the defaults */
    overrideBookData: O,
  ) {
    const { ageToBe, kunChan } = createBookData;
    const kidstitle = getKidstitle(kunChan);
    const newBook: Omit<IBookFroebelkanData, TCreateUserBookOmitKeys> = {
      ...createBookData,
      birthDateTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      birthTime: '',
      steps: {
        [INPUT_VALUE_STEP_CHARACTER]: {
          stepType: INPUT_VALUE_STEP_CHARACTER,
          isDone: true,
        },
        [INPUT_VALUE_STEP_COVER_IMAGE]: {
          stepType: INPUT_VALUE_STEP_COVER_IMAGE,
          isDone: false,
        },
        [INPUT_VALUE_STEP_AGE_TO_BE_BIRTH_TIME]: {
          stepType: INPUT_VALUE_STEP_AGE_TO_BE_BIRTH_TIME,
          isDone: false,
        },
        [INPUT_VALUE_STEP_PHOTO]: {
          stepType: INPUT_VALUE_STEP_PHOTO,
          isDone: false,
        },
        [INPUT_VALUE_STEP_WHOS_CELEBRATING]: {
          stepType: INPUT_VALUE_STEP_WHOS_CELEBRATING,
          isDone: false,
        },
        [INPUT_VALUE_STEP_MESSAGE]: {
          stepType: INPUT_VALUE_STEP_MESSAGE,
          isDone: false,
        },
      },
      // uid: undefined,
      // bookId: undefined,
      // fileUploadStatus: 0,
      multiFiles: {
        [PHOTO_FROEBELKAN]: {
          fileUploadStatus: 0,
        },
        [PHOTO_FROEBELKAN_2]: {
          fileUploadStatus: 0,
        },
        [PHOTO_FROEBELKAN_3]: {
          fileUploadStatus: 0,
        },
        [PHOTO_FROEBELKAN_4]: {
          fileUploadStatus: 0,
        },
        [PHOTO_FROEBELKAN_5]: {
          fileUploadStatus: 0,
        },
      },
      // createTimeStamp: _serverTimestamp,
      alias: Froebelkan.alias,
      cover: Froebelkan.cover,
      coverImage: 'A',
      coverRibbon: 'red',
      birthHeight: '',
      height: '',
      birthWeight: '',
      weight: '',
      characters: {
        [INPUT_VALUE_CHARACTERS_C01]: false,
        [INPUT_VALUE_CHARACTERS_C02]: false,
        [INPUT_VALUE_CHARACTERS_C03]: false,
        [INPUT_VALUE_CHARACTERS_C04]: false,
        [INPUT_VALUE_CHARACTERS_C05]: false,
        [INPUT_VALUE_CHARACTERS_C06]: false,
      },
      messageText:
        `${createBookData.heroName}${kidstitle}へ\n\n` +
        `${ageToBe}さいの　まいにちも\n` +
        `たのしいことを　たくさん　みつけて\n` +
        `すてきな　いちねんにしてね。`,

      messageText2:
        `${createBookData.heroName}${kidstitle}が\n` +
        `うまれて　まいにちが　とくべつ。\n` +
        `うれしい　ひも、たいへんな　ひも\n` +
        `いっしょに　すごしていこう。\n` +
        `あなたの　えがおが\n` +
        `ずっとずっと　つづきますように。\n` +
        `\n` +
        `おたんじょうびおめでとう！`,
    };

    return { ...newBook, ...overrideBookData };
  }

  override async loadBook(
    bookId: string,
    onBookDataLoad:
      | ((bookData: IBookFroebelkanData) => Promise<void>)
      | ((bookData: IBookFroebelkanData) => void),
    mode: TBookMode = ROUTE_PATH_QUERY_PARAM_MODE_USER,
  ) {
    const bookData = await super.loadBook(bookId, onBookDataLoad, mode);

    this._bookPagesService.newBookInit(bookData);

    const multiFilesEntries = Object.entries(bookData.multiFiles);
    this._loaderService.pushProgressMax(multiFilesEntries.length * 2);

    await Promise.all(
      multiFilesEntries.map(async ([photoName, data]) => {
        if (data.fileUploadStatus === 10) {
          log.info('loading', photoName);
          await this._multiFileLoad(photoName);
          log.info('loaded', photoName);
        }

        this._loaderService.pushProgressValue(2);
      }),
    );

    this.textChangeSubscribe.next();

    return bookData;
  }
}
