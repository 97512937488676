<a
  class="edit"
  [routerLink]="link"
>
  <svg-icon
    class="edit__svg-icon"
    src="assets/images/icons/cart-product-edit.svg"
    [applyClass]="true"
  ></svg-icon>
  <span class="edit__text"><ng-content></ng-content></span>
</a>
