import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { RouterLink } from '@angular/router';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditComponent {
  @Input() link: RouterLink['routerLink'];

  // constructor() {}
}
