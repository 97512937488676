<div class="container">
  <div class="container__header">
    <label
      class="label"
      [for]="id"
      >{{ label }}</label
    >
  </div>
  <div class="container__content">
    <div class="content">
      <div class="content__data">
        <ng-content></ng-content>
      </div>
      <div class="content__button">
        <button
          [id]="id"
          class="copy-button"
          (click)="copyButtonClick()"
        >
          <svg-icon
            class="copy-button__ico"
            src="assets/images/icons/copy.svg"
            [applyClass]="true"
          ></svg-icon>
          <span class="copy-button__txt">コピー</span>
        </button>
      </div>
    </div>
  </div>
</div>
