<div class="container">
  <div class="container__title">
    <!-- <h1 class="title">世界でたったひとつ　あなたのための一冊が作れる</h1> -->
    <h1 class="title">
      世界でたったひとつ　<br />
      あなたのための１冊がつくれる
    </h1>
  </div>
  <!-- <div class="container__content">
    <p class="content">
      「えほんやさんセカイチ」お子さん一人ひとりに<br />
      パーソナライズされた絵本をオーダーメイドでつくれるサイトです。<br />
      成長の記録や思い出の写真、メッセージとともに<br />
      世界で1冊しかない絵本づくりを楽しんでください。
    </p>
  </div> -->
  <div class="container__button">
    <button
      class="button"
      (click)="onButtonClick()"
    >
      <span class="button__text">購入する</span>
    </button>
  </div>
</div>
