import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceModule } from '@studiobuki/web-core/lib/price';
import { ProductComponent } from './product.component';
import { SpecComponent } from './components/spec/spec.component';
import { PhotoComponent } from './components/photo/photo.component';
import { TitleComponent } from './components/title/title.component';
import { DefaultComponent } from './components/default/default.component';
import { MobileComponent } from './components/mobile/mobile.component';

@NgModule({
  declarations: [
    ProductComponent,
    SpecComponent,
    PhotoComponent,
    TitleComponent,
    DefaultComponent,
    MobileComponent,
  ],
  imports: [CommonModule, PriceModule],
  exports: [ProductComponent],
})
export class ProductModule {}
