<div class="wrap">
  <div class="wrap__photo">
    <app-photo
      [src]="photo"
      [alt]="title"
    ></app-photo>
  </div>
  <div class="wrap__description">
    <div class="description">
      <div class="description__title">
        <app-title
          [title]="title"
          [subtitle]="subtitle"
        ></app-title>
      </div>
      <div class="description__specs">
        <div class="description-specs">
          <div
            class="description-specs__specs"
            *ngIf="specs"
          >
            <app-spec
              *ngFor="let key of specsKeys"
              [key]="key"
              [value]="specs[key] || ''"
            ></app-spec>
          </div>
        </div>
      </div>
      <div
        *ngIf="price"
        class="description__price"
      >
        <lib-price
          class="price"
          [price]="price.value"
          [currency]="price.currency"
          [discount]="discount"
        ></lib-price>
      </div>
    </div>
  </div>
  <div
    *ngIf="price"
    class="wrap__price"
  >
    <lib-price
      class="price"
      [price]="price.value"
      [currency]="price.currency"
      [discount]="discount"
      [type]="(media.isLtXl$ | async) ? 'H' : 'V'"
    ></lib-price>
  </div>
</div>
