<div class="container">
  <div
    class="container__header"
    (click)="toggleExpanded()"
  >
    <app-header [title]="title">
      <div
        *ngIf="isHeaderContentVisible"
        class="header-content"
      >
        <div
          *ngIf="isHeaderToggleVisible"
          class="header-content__item header-content__item_toggle"
        >
          <app-toggle text="確認する"></app-toggle>
        </div>
        <div
          *ngIf="isHeaderLinkVisible"
          class="header-content__item header-content__item_link"
        >
          <app-link
            (buttonClick)="onEditCartClick()"
            linkSrc="assets/images/icons/order-aside-edit.svg"
            text="編集する"
          ></app-link>
        </div>
        <div
          *ngIf="isHeaderCalculatorVisible"
          class="header-content__item header-content__item_calculator"
        >
          <lib-calculator
            [items]="calculatorItems"
            [type]="CalculatorComponentType.lite"
          ></lib-calculator>
        </div>
      </div>
    </app-header>
  </div>
  <div
    class="container__content"
    *ngIf="isExpanded"
    [@slideY]
  >
    <div class="content">
      <div class="content__link">
        <app-link
          (buttonClick)="onEditCartClick()"
          linkSrc="assets/images/icons/order-aside-edit.svg"
          text="編集する"
        ></app-link>
      </div>
      <div
        class="content__product"
        *ngFor="let p of products"
      >
        <ng-container
          *ngIf="
            discountService.activeDiscountCampaign$ | async as discountCampaign;
            else nonDiscountProduct
          "
        >
          <app-product
            [id]="p.bookId"
            [title]="p.name"
            [subtitle]="p.cover.name"
            [specs]="p.specs"
            [price]="getProductPrice(p)"
            [photo]="p.image"
            [discount]="getBooksDiscount(discountCampaign, p.alias)"
          ></app-product>
        </ng-container>
        <ng-template #nonDiscountProduct>
          <app-product
            [id]="p.bookId"
            [title]="p.name"
            [subtitle]="p.cover.name"
            [specs]="p.specs"
            [price]="getProductPrice(p)"
            [photo]="p.image"
          ></app-product>
        </ng-template>
      </div>
      <div
        *ngIf="calculatorItems"
        class="content__calculator"
      >
        <lib-calculator
          [items]="calculatorItems"
          [type]="CalculatorComponentType.order"
        ></lib-calculator>
      </div>
      <div
        *ngIf="!alwaysExpanded"
        class="content__toggle"
        (click)="toggleExpanded()"
      >
        <app-toggle
          type="top"
          text="閉じる"
        ></app-toggle>
      </div>
    </div>
  </div>
</div>
