import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-tick',
  templateUrl: './tick.component.html',
  styleUrls: ['./tick.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TickComponent {
  @Input() active = false;

  @Input() hover = false;

  // constructor() {}
}
