<div
  class="container"
  *ngIf="items"
>
  <div
    class="container__section"
    *ngFor="let item of items"
  >
    <ng-container
      *ngIf="item.templateRef"
      [ngTemplateOutlet]="item.templateRef"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
  </div>
</div>
