<!-- eslint-disable prettier/prettier -->
<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__background"></div>
      <div class="container__content">
        <div class="content">
          <div class="content__caption">
            <h1 class="caption">
              <pre>世界に１冊だけの絵本を、
  <ng-container *ngIf="(media.isLtMd$ | async) === false">              </ng-container>世界に一人だけのために</pre>
            </h1>
          </div>
          <div class="content__text">
            <p class="text">
              贈る人の想いがこもった<br />
              特別な絵本といっしょに<br />
              特別な時間をお届けします
            </p>
          </div>
          <!-- <div class="content__text">
            <p class="text">子供はみんな、特別な存在だから。</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
