import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewModule } from '@studiobuki/web-core/lib/preview';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionFooterBannersModule } from 'src/app/shared/section-footer-banners/section-footer-banners.module';
import { AboutPageComponent } from './about-page.component';
import { SectionHeaderBannerComponent } from './components/section-header-banner/section-header-banner.component';

@NgModule({
  declarations: [AboutPageComponent, SectionHeaderBannerComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    PreviewModule,
    SectionFooterBannersModule,
  ],
})
export class AboutPageModule {}
