import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EFormShipFromValue } from './form-ship-from.enum';

@Component({
  selector: 'app-form-ship-from',
  templateUrl: './form-ship-from.component.html',
  styleUrls: ['./form-ship-from.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormShipFromComponent {
  @Input() set value(value: EFormShipFromValue) {
    if (value !== this.value$.value) {
      this.value$.next(value);
      this.valueChange.emit(value);
    }
  }

  public value$ = new BehaviorSubject<EFormShipFromValue>(
    EFormShipFromValue.buki,
  );

  @Output() valueChange = new EventEmitter<EFormShipFromValue>();

  public readonly EFormShipFromValue = EFormShipFromValue;

  // constructor() {}

  public onElementClick(value: EFormShipFromValue): void {
    this.value = value;
  }
}
