import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { InputModule } from '@studiobuki/web-core/lib/input';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { NavigationDropdownComponent } from './components/navigation-dropdown/navigation-dropdown.component';
import { NavigationDropdownItemComponent } from './components/navigation-dropdown-item/navigation-dropdown-item.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { BookButtonComponent } from './components/book-button/book-button.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    NavigationItemComponent,
    NavigationDropdownComponent,
    NavigationDropdownItemComponent,
    MenuItemComponent,
    BookButtonComponent,
    TopbarComponent,
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    AngularSvgIconModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    InputModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
