<p class="container">
  <span
    *ngFor="let s of steps; let i = index"
    class="container__item"
    [class.container__item_active]="s.id === step"
  >
    <ng-container *ngIf="i !== 0">　＞　</ng-container>
    {{ s.name }}
  </span>
</p>
