<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
    *ngIf="media.isLtXl$ | async"
  ></app-section-header-gap>
  <app-section-header-banner
    class="wrap__section-header-banner"
  ></app-section-header-banner>
  <lib-description
    class="wrap__section-description"
    [description]="descriptionData"
  ></lib-description>
  <app-section-footer-banners
    class="wrap__section-footer-banners"
  ></app-section-footer-banners>
</div>
