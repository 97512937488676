import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionTextModule } from '@studiobuki/web-core/lib/section-text';
import { TermsPageComponent } from './terms-page.component';

@NgModule({
  declarations: [TermsPageComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    SectionTextModule,
    // SectionFooterBannerModule,
  ],
})
export class TermsPageModule {}
