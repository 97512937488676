<div class="container">
  <div class="container__section">
    <a
      class="link"
      routerLink="/policy"
      >プライバシーポリシー</a
    >
  </div>
  <div class="container__section">
    <a
      class="link"
      routerLink="/terms"
      >利用規約</a
    >
  </div>
  <div class="container__section">
    <a
      class="link"
      routerLink="/ordercontract"
      >特定商取引法に基づく表示</a
    >
  </div>
</div>
