import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MediaService } from '@studiobuki/web-core/lib/media';

@Component({
  selector: 'app-section-header-banner',
  templateUrl: './section-header-banner.component.html',
  styleUrls: ['./section-header-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionHeaderBannerComponent {
  constructor(public media: MediaService) {}
}
