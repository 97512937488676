<div class="wrap">
  <div class="wrap__title">
    <lib-card
      [title]="'LINEで問い合わせる'"
      [noContent]="true"
    ></lib-card>
  </div>
  <div class="wrap__section">
    <div class="aside-container">
      <div class="aside-container__character"></div>
      <div class="aside-container__content">
        ご連絡をお急ぎの方・<br />メールが届かない方はLINEで<br />お問い合わせください
      </div>
    </div>
  </div>
  <div class="wrap__section">
    <lib-card
      borderTopLeftRadius="0px"
      [borderBottomLeftRadius]="!(media.isLtMd$ | async) ? '0px' : undefined"
    >
      <div class="card-container">
        <div class="card-container__title">
          <p class="title">
            LINEで友だちに追加してお問い合わせ内容<br />をチャットに送信してください
          </p>
        </div>
        <div class="card-container__content">
          <a
            class="link"
            href="https://lin.ee/1pcxNl6"
          >
            <img
              class="link__img"
              src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
              alt="友だち追加"
            />
          </a>
          <div class="qr">
            <img
              class="qr__img"
              src="https://qr-official.line.me/sid/M/955leonc.png"
            />
          </div>
        </div>
      </div>
    </lib-card>
  </div>
</div>
