import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ScrollTopComponent } from './scroll-top.component';

@NgModule({
  declarations: [ScrollTopComponent],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [ScrollTopComponent],
})
export class ScrollTopModule {}
