import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { ImageTitleModule } from '@studiobuki/web-core/lib/image-title';
import { InputModule } from '@studiobuki/web-core/lib/input';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { GiftUsePageComponent } from './gift-use-page.component';
import { ContentModule } from '../gift-thanks-page/components/content/content.module';
import { SectionGiftHeaderComponent } from './components/section-gift-header/section-gift-header.component';
import { SectionGiftUseComponent } from './components/section-gift-use/section-gift-use.component';
import { BookFormDialogModule } from './components/book-form-dialog/book-form-dialog.module';

@NgModule({
  declarations: [
    GiftUsePageComponent,
    SectionGiftHeaderComponent,
    SectionGiftUseComponent,
  ],
  imports: [
    CommonModule,
    ContentModule,
    SectionHeaderGapModule,
    ImageTitleModule,
    InputModule,
    ButtonModule,
    BookFormDialogModule,
  ],
})
export class GiftUsePageModule {}
