<div class="wrap">
  <div class="wrap__character"></div>
  <lib-card class="wrap__container">
    <div class="container">
      <div class="container__title">
        <p class="title">
          <strong>ご注文内容確認のメールをお送りしました。</strong><br />
          メールが届いていない場合は、<br />
          LINEでお問い合わせください。
        </p>
      </div>
      <div class="container__content">
        <a
          class="link"
          href="https://lin.ee/1pcxNl6"
          target="_blank"
        >
          <img
            class="link__img"
            src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
            alt="友だち追加"
          />
        </a>
        <div class="qr">
          <img
            class="qr__img"
            src="https://qr-official.line.me/sid/M/955leonc.png"
          />
        </div>
      </div>
    </div>
  </lib-card>
</div>
