import { Injectable } from '@angular/core';
import type { NavigationExtras } from '@angular/router';
import { Router } from '@angular/router';
import type {
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL,
  ROUTE_PATH_PARAM_GIFT_ID,
  ROUTE_PATH_PARAM_ORDER_ID,
  TAuthQueryParams,
  TAuthType,
} from '@studiobuki/web-core/lib/routing';
import {
  ROUTE_PATH_AUTH,
  ROUTE_PATH_404,
  ROUTE_PATH_ABOUT,
  ROUTE_PATH_BOOK,
  ROUTE_PATH_CART,
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_COVER,
  ROUTE_PATH_FAQ,
  ROUTE_PATH_GIFT_BUY,
  ROUTE_PATH_GIFT_ORDER,
  ROUTE_PATH_GIFT_THANKS,
  ROUTE_PATH_GIFT_USE,
  ROUTE_PATH_ORDER,
  ROUTE_PATH_POLICY,
  ROUTE_PATH_QUERY_PARAM_MODE,
  ROUTE_PATH_QUERY_PARAM_MODE_PRINT,
  ROUTE_PATH_TERMS,
  ROUTE_QUERY_PARAM_BOOK,
  ROUTE_QUERY_PARAM_GIFT,
} from '@studiobuki/web-core/lib/routing';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private _router: Router) {}

  public goToIndex(extras?: NavigationExtras) {
    return this._router.navigate(['/'], extras);
  }

  public goToAbout() {
    return this._router.navigate([ROUTE_PATH_ABOUT]);
  }

  public goToTerms() {
    return this._router.navigate([ROUTE_PATH_TERMS]);
  }

  public goToPolicy() {
    return this._router.navigate([ROUTE_PATH_POLICY]);
  }

  public goToFAQ() {
    return this._router.navigate([ROUTE_PATH_FAQ]);
  }

  public goToContacts() {
    return this._router.navigate([ROUTE_PATH_CONTACTS]);
  }

  public goToGiftBuy() {
    return this._router.navigate([ROUTE_PATH_GIFT_BUY]);
  }

  public goToGiftUse(giftId: string) {
    return this._router.navigate([ROUTE_PATH_GIFT_USE, giftId]);
  }

  public goToGiftThanks(queryParams?: {
    [ROUTE_PATH_PARAM_ORDER_ID]?: string;
    [ROUTE_PATH_PARAM_GIFT_ID]?: string;
  }) {
    return queryParams
      ? this._router.navigate([ROUTE_PATH_GIFT_THANKS], { queryParams })
      : this._router.navigate([ROUTE_PATH_GIFT_THANKS]);
  }

  public goToCover(bookId: string) {
    return this._router.navigate([ROUTE_PATH_COVER, bookId], {
      queryParamsHandling: 'merge',
    });
  }

  public goToCart() {
    return this._router.navigate([ROUTE_PATH_CART]);
  }

  public goToOrder(orderId: string) {
    return this._router.navigate([
      ROUTE_PATH_ORDER.replace('/:orderId', ''),
      orderId,
    ]);
  }

  public goToGiftOrder(orderId: string, extras?: NavigationExtras) {
    return this._router.navigate([ROUTE_PATH_GIFT_ORDER, orderId], extras);
  }

  public goToGiftOrderCreation(
    bookId: string,
    giftId: string,
    extras?: NavigationExtras,
  ) {
    return this._router.navigate(
      [ROUTE_PATH_GIFT_ORDER],
      Object.assign<NavigationExtras, NavigationExtras>(extras || {}, {
        queryParams: {
          [ROUTE_QUERY_PARAM_GIFT]: giftId,
          [ROUTE_QUERY_PARAM_BOOK]: bookId,
        },
      }),
    );
  }

  public goToPrintBook(taskId: string) {
    return this._router.navigate(
      [ROUTE_PATH_BOOK.replace('/:id', ''), taskId],
      {
        // replaceUrl: true,
        queryParams: {
          [ROUTE_PATH_QUERY_PARAM_MODE]: ROUTE_PATH_QUERY_PARAM_MODE_PRINT,
        },
      },
    );
  }

  public goToBook(bookId: string, giftId?: string) {
    return this._router.navigate(
      [ROUTE_PATH_BOOK.replace('/:id', ''), bookId],
      {
        // replaceUrl: true,
        queryParams: {
          [ROUTE_QUERY_PARAM_GIFT]: giftId,
        },
      },
    );
  }

  public goTo404() {
    return this._router.navigate([ROUTE_PATH_404]);
  }

  public goToAuth(
    type: typeof ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL,
    queryParams: Pick<TAuthQueryParams, 'email'>,
  ): Promise<boolean>;
  public goToAuth(
    type: Exclude<TAuthType, typeof ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL>,
    queryParams?: TAuthQueryParams,
  ): Promise<boolean>;
  public goToAuth(type: TAuthType, queryParams?: TAuthQueryParams) {
    return queryParams
      ? this._router.navigate([ROUTE_PATH_AUTH, type], { queryParams })
      : this._router.navigate([ROUTE_PATH_AUTH, type]);
  }
}
