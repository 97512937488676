import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqModule } from '@studiobuki/web-core/lib/faq';
import { SectionFaqModule } from '@studiobuki/web-core/lib/section-faq';
import { SectionGiftFaqComponent } from './section-gift-faq.component';

@NgModule({
  declarations: [SectionGiftFaqComponent],
  imports: [CommonModule, FaqModule, SectionFaqModule],
  exports: [SectionGiftFaqComponent],
})
export class SectionGiftFaqModule {}
