import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { PriceModule } from '@studiobuki/web-core/lib/price';
import { ProductComponent } from './product.component';
import { DefaultComponent } from './components/default/default.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { MessageComponent } from './components/message/message.component';
import { EditComponent } from './components/edit/edit.component';
import { SpecComponent } from './components/spec/spec.component';
import { XButtonComponent } from './components/x-button/x-button.component';
import { PhotoComponent } from './components/photo/photo.component';
import { CoverComponent } from './components/cover/cover.component';
import { TitleComponent } from './components/title/title.component';

@NgModule({
  declarations: [
    ProductComponent,
    DefaultComponent,
    MobileComponent,
    MessageComponent,
    EditComponent,
    SpecComponent,
    XButtonComponent,
    PhotoComponent,
    CoverComponent,
    TitleComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    PriceModule,
    FlexLayoutModule,
    RouterModule,
  ],
  exports: [ProductComponent],
})
export class ProductModule {}
