import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PriceModule } from '@studiobuki/web-core/lib/price';
import { SelectOptionComponent } from './select-option.component';
import { TickComponent } from './components/tick/tick.component';
import { TitleComponent } from './components/title/title.component';
import { PhotoComponent } from './components/photo/photo.component';
import { DefaultComponent } from './components/default/default.component';
import { MobileComponent } from './components/mobile/mobile.component';

@NgModule({
  declarations: [
    SelectOptionComponent,
    TickComponent,
    TitleComponent,
    PhotoComponent,
    DefaultComponent,
    MobileComponent,
  ],
  imports: [CommonModule, AngularSvgIconModule, PriceModule],
  exports: [SelectOptionComponent],
})
export class SelectOptionModule {}
