<div
  *ngIf="data"
  class="container"
  [ngClass]="['container_' + data.alias]"
>
  <div class="container__title">
    <h2 class="title">
      世界に一冊の絵本<br />
      <b>「アンパンマンのおたんじょうびおめでとう！」</b><br />
      がプレゼントされました！
    </h2>
  </div>
  <div class="container__subtitle">
    <h3 class="subtitle">この絵本の主人公になる、お誕生日の子は、誰ですか？</h3>
  </div>
  <lib-book-form
    class="container__content"
    [alias]="data.alias"
    [inputs]="data.inputs"
    (submitted)="onSubmit($event)"
    [unwrapped]="true"
  ></lib-book-form>
</div>
