<figure
  class="container"
  [class.container_active]="active"
>
  <div
    *ngIf="recommended && (media.isLtLg$ | async) === false"
    class="container__label"
  >
    <ng-container *ngTemplateOutlet="label"></ng-container>
  </div>
  <div class="container__photo">
    <div class="carousel">
      <div class="carousel__content">
        <swiper
          [autoHeight]="true"
          [navigation]="{
            prevEl: navLeft,
            nextEl: navRight,
            disabledClass: 'navigation__item_disabled'
          }"
          [loop]="true"
          [pagination]="{
            clickable: true
          }"
        >
          <ng-template
            *ngFor="let src of carousel"
            swiperSlide
          >
            <img
              class="photo"
              [src]="src"
              alt="#"
            />
          </ng-template>
        </swiper>
      </div>
      <div class="carousel__navigation">
        <div class="navigation">
          <button
            #navLeft
            class="navigation__item navigation__item_left"
          ></button>
          <button
            #navRight
            class="navigation__item navigation__item_right"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <div class="container__content">
    <div class="content">
      <div
        *ngIf="recommended && (media.isLtLg$ | async)"
        class="content__label"
      >
        <ng-container *ngTemplateOutlet="label"></ng-container>
      </div>
      <div class="content__header">
        <div class="header">
          <div class="header__title">
            <h3 class="title">{{ title }}</h3>
          </div>
          <div class="header__price">
            <lib-price
              class="price"
              size="sm"
              spacing="lg"
              [price]="price.value"
              [currency]="price.currency"
              [shippingIncluded]="price.shippingIncluded"
            ></lib-price>
          </div>
        </div>
      </div>
      <div class="content__summary">
        <figcaption
          class="summary"
          [innerHTML]="summary"
        ></figcaption>
      </div>
      <div class="content__footer">
        <lib-button
          (buttonClick)="buttonClick.emit()"
          [fullWidth]="true"
          [bordered]="!active"
          >{{ active ? buttonTextActive : buttonText }}</lib-button
        >
      </div>
    </div>
  </div>
</figure>

<ng-template #label>
  <p class="label">おすすめ！人気です！</p>
</ng-template>
