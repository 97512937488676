import { Component } from '@angular/core';
import type { IArticle } from '@studiobuki/web-core/lib/section-text';

@Component({
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss'],
})
export class TermsPageComponent {
  public readonly articles: IArticle[] = [
    {
      caption: '利用規約',
      passages: [
        {
          items: [
            {
              type: 'caption',
              text: '第1条　目的',
            },
            {
              type: 'text',
              text: 'STUDIO BUKI株式会社は、本サイトを適正かつ円滑に運営し、お客様にギフトえほん　セカイチ【以下、本サービス】を快適にご利用いただくため、本規約を定めます。',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第2条　同意',
            },
            {
              type: 'text',
              text: 'お客様が本サービスを利用されるにあたっては、「本規約」の他「プライバシーポリシー」もご確認ください。 また、本サービスを利用した場合は、「本規約」、「プライバシーポリシー」にご利用者が同意したものとみなされます。',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第3条　本規約の適用範囲及び変更',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: '本規約は本サービスの提供および利用に関し、当社とご利用者に適用されるものとします。',
                },
                {
                  text: '当社は、ご利用者の事前の承諾を得ることなく、本規約を変更できるものとします。またその場合は最新のものを遵守くださるようお願いいたします。',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第4条　ご利用者の定義',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: 'ご利用者とは、本サイトを閲覧、購入等、利用を行ったお客さまをいいます。',
                },
                {
                  text: '日本国内居住のご利用者のみ対象とさせていただきます。',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第5条　オンラインショッピングサービスの利用',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: 'ご利用者は、本規約に従い、本サービスを利用していただくものとします。',
                },
                {
                  text: '当社は、ご利用者に事前通知することなく、本サービスの内容を変更することができるものとします。これによりお客様に生じた損害について、当社は一切責任を追わないものとします。',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第6条　登録情報の利用',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: '当社は、本サイトにて取得し、管理するお客様の登録情報を以下の目的で利用いたします。くわしくは「プライバシーポリシー」をご確認ください。',
                  items: [
                    {
                      text: '商品や資料の発送、代金決済、各種お客様との連絡のため',
                    },
                    {
                      text: 'メルマガ、ＤＭ等によるご案内、アンケートなどの販売促進のため',
                    },
                    {
                      text: '個人を特定できない形での統計データの作成のため',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第7条　支払い',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: '商品の購入にあたりお支払いいただく金額は、商品購入代金、送料および消費税の合計額となります。',
                },
                {
                  text: '本サービスによって購入された商品のお支払いは、クレジットカードにより支払われるものとします。',
                },
                {
                  text: 'クレジットカード情報は暗号化されてクレジットカード決済代行会社に送信されます。クレジットカードに関するいかなる情報は当社サーバーには保存されません。',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第8条　商品の返品',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: '配送中の破損、商品に印刷ミスや汚れなどの不備があった場合を除き、商品のキャンセル・返品・交換はできないものとします。',
                },
                {
                  text: '配送中の破損、商品に印刷ミスや汚れなどの不備が認められた場合には、発送から14日以内であれば、新しいものとの交換で対応いたします。',
                },
                {
                  text: 'なお、本サービスはクーリング・オフの対象外です。',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第9条　禁止事項',
            },
            {
              type: 'text',
              text: '本サービスご利用に際して、次の各号の行為を禁止します。 万一、これらに違反された場合、当社は本サービスの提供を停止したり、以後の取引をお断りすることがあります。またご利用者の違反行為により当社に損害が生じた場合は、ご利用者がその損害を賠償する責任を負うものとします。',
            },
            {
              type: 'ordered-list',
              listStyleType: 'lower-alpha',
              items: [
                {
                  text: '情報入力の際に虚偽の内容を申請する行為',
                },
                {
                  text: '本サービスの運営を妨げる行為、その他オンラインショッピングサービスに支障をきたす恐れのある行為',
                },
                {
                  text: '他のご利用者、第三者、もしくは当社の権利、利益、名誉等を損ねる行為、またはそれらの恐れのある行為',
                },
                {
                  text: '他のご利用者、第三者、もしくは当社の商標権、著作権、プライバシーその他の権利を侵害する行為、またはそれらの恐れのある行為',
                },
                {
                  text: '公序良俗に反する行為その他法令に違反する行為、またはそれらの恐れのある行為',
                },
                {
                  text: 'その他、当社が不適当と判断する行為',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第10条　サービスの提供停止',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: '当社は、ご利用者が第11条各号のいずれかに該当する場合、および以下の項目のいずれかに該当する場合、ご利用者に事前通知することなく本サービスの提供の停止を行うことができるものとします。',
                  listStyleType: 'lower-alpha',
                  items: [
                    {
                      text: '過去に本規約に違反していることが判明した場合',
                    },
                    {
                      text: '本サービスに関する料金等の支払債務の履行遅延その他の不履行があった場合',
                    },
                    {
                      text: 'ご利用者の支払い能力が危うくなったと認めうる事情が判明した場合',
                    },
                    {
                      text: '商品が品切れとなり、容易にお届けできない場合',
                    },
                    {
                      text: '届先不明・長期不在でお届けできない場合',
                    },
                    {
                      text: 'その他本規約に違反した場合',
                    },
                  ],
                },
                {
                  text: '当社が前項の措置をとったことで、当該ご利用者または第三者に不利益、損害が発生したとしても、当社は一切の責任を負わないものとします。',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第11条　サービスの停止',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: '当社は、サービスの稼動状態を良好に保つために、次の各号のいずれかに該当する場合、ご利用者に事前に通知を行うことなく本サービスの提供の全部あるいは一部を停止することができるものとします。',
                  listStyleType: 'lower-alpha',
                  items: [
                    {
                      text: 'システムの定期保守および緊急保守のために必要な場合',
                    },
                    {
                      text: '火災、停電、第三者による妨害行為等によりシステムの運用が困難になった場合',
                    },
                    {
                      text: 'その他、止むを得ずシステムの停止が必要と当社が判断した場合',
                    },
                  ],
                },
                {
                  text: 'このような事態に伴い、ご利用者または第三者に不利益、損害が発生したとしても、当社は一切の責任を負わないものとします',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第12条　財産権',
            },
            {
              type: 'text',
              text: '当社が提供するシステム、プラットフォーム、ツール、およびこれらにより生成されるロゴ・レイアウト・デザイン等を含む一切のソフトウェアに関する著作権、意匠権、商標権、特許権、実用新案権等やこれらを受ける権利、営業秘密等の知的財産権、その他一切の権利は、当社に帰属します。',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第13条　免責事項',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: '当社は、本サービスを現状そのままで提供するものとし、その内容の信頼性、真実性、適法性等について、明示または黙示を問わず、適用される法律の許す限りにおいて、いかなる保証または表明も行わないものとします。',
                },
                {
                  text: 'ご利用者は、本サービスの利用にあたって、すべてご自分の責任においてこれを行うことに同意されたものとみなされます。当社は、ご利用者と当社との間に別段の合意がある場合を除き、いかなる場合においても、本サービスの利用によって、またはサービスを利用できなかったことによって、ご利用者またはご利用者以外の第三者に生じるあらゆる損害、損失、不利益等に関して責任を負わないものとします。',
                },
                {
                  text: '当社は、ご利用者に対して通知義務を負う場合は、ご利用者が予め入力あるいは登録しているメールアドレス、電話または住所へ通知を発信することにより、また、商品のお届けについては、商品購入の際、お客さまに指示された送付先に商品を配送等することにより、その義務を果たしたものとします。',
                },
                {
                  text: 'ご利用者は、本サービスの利用に用いるウェブブラウザとして、当社が推奨するブラウザを使用するものとします。当社指定以外のブラウザを使用したことにより、画面が正しく表示されない等の問題が生じた場合、当社は一切の責任を負わず、また一切の質問を受け付けないものとします。',
                },
                {
                  text: 'ご利用者が本サービスを利用するのに必要なコンピュータ機器及び通信機器等の設置に関する費用、サービスを利用するために要した電話料金、LAN等の利用料および申請料金等は、ご利用者ご自身にご負担いただきます。',
                },
                {
                  text: '当社は、当社が提供するデータや他者が登録するデータ等について、その正確性、動作性等に関し、いかなる責任も負わないものとします。',
                },
                {
                  text: '当社は、会員が当サイトに蓄積した、または会員が他者に蓄積することを承認したデータ等の消失、破壊、他者による改竄に関し、いかなる責任も負わないものとします。',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第14条　適用法',
            },
            {
              type: 'text',
              text: '本ウェブサイトは法律の異なる全世界の国々からアクセスすることが可能ですが、本ウェブサイトにアクセスされたご利用者および当社の両者は、かかる法律の原理の違いに関わらず、本ウェブサイトの利用に関して日本国の法令に拘束されることに同意するものとします。本ウェブサイトへのアクセスはお客さまの自由意思によるものとし、本ウェブサイトの利用に関しての責任はお客さまにあるものとします。',
            },
          ],
        },
        {
          items: [
            {
              type: 'caption',
              text: '第15条　その他',
            },
            {
              type: 'ordered-list',
              items: [
                {
                  text: '当社とご利用者との連絡方法は、原則として電話およびメールによるものとします。',
                },
                {
                  text: '本サービスのご利用に関して、本利用規約により解決できない問題が生じた場合には、当社とご利用者との間で双方誠意をもって話し合い、これを解決するものとします。',
                },
                {
                  text: '本サービスの利用に関してご利用者と当社との間に生じた紛争については、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。',
                },
              ],
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '制定：令和　6年　2月　1日',
            },
            {
              type: 'text',
              text: 'STUDIO BUKI株式会社　代表取締役　コズロブふくみ',
            },
          ],
        },
      ],
    },
  ];
}
