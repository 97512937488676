<div class="wrap">
  <app-section-header-gap class="wrap__header-gap"></app-section-header-gap>
  <lib-image-title
    type="cart"
    class="wrap__image-title"
    >My Page</lib-image-title
  >
  <div class="wrap__content">
    <div
      class="content"
      [ngSwitch]="type"
    >
      <app-auth-card
        [title]="'auth.sign-in.title'"
        *ngSwitchCase="ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN"
      >
        <app-form-sign-in
          (signInButtonClick)="onSignInButtonClick($event)"
          (signUpButtonClick)="onSignUpButtonClick()"
          (resetButtonClick)="onResetButtonClick()"
        ></app-form-sign-in>
      </app-auth-card>
      <app-auth-card
        [title]="'auth.sign-up.title'"
        *ngSwitchCase="ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP"
      >
        <app-form-sign-up
          (signUpButtonClick)="onSignUpButtonClick($event)"
          (signInButtonClick)="onSignInButtonClick()"
        ></app-form-sign-up>
      </app-auth-card>
      <app-auth-card
        [title]="'auth.sign-up-final.title'"
        *ngSwitchCase="ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL"
      >
        <app-form-sign-up-final
          *ngIf="queryParams?.email as email"
          [email]="email"
          (signUpFinalButtonClick)="onSignUpFinalButtonClick()"
        ></app-form-sign-up-final>
      </app-auth-card>
      <app-auth-card
        [title]="'auth.reset.title'"
        *ngSwitchCase="ROUTE_PATH_PARAM_AUTH_TYPE_RESET"
      >
        <app-form-reset
          (resetButtonClick)="onResetButtonClick($event)"
          (signInButtonClick)="onSignInButtonClick()"
        ></app-form-reset>
      </app-auth-card>
    </div>
  </div>
</div>
