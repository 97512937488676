<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
  ></app-section-header-gap>
  <div class="wrap__section-image-title">
    <lib-image-title type="contact">ご質問などはこちら</lib-image-title>
  </div>
  <app-section-form class="wrap__section-form"></app-section-form>
  <!-- <app-section-collaboration
    class="wrap__section-collaboration"
  ></app-section-collaboration>
  <app-section-ducks
    class="wrap__section-ducks"
  ></app-section-ducks> -->
  <app-section-footer-banner
    class="wrap__section-footer-banner"
  ></app-section-footer-banner>
</div>
