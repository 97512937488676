import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from '@studiobuki/web-core/lib/card';
import { RouterModule } from '@angular/router';
import { ImageTitleModule } from '@studiobuki/web-core/lib/image-title';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionFooterBannerModule } from 'src/app/shared/section-footer-banner/section-footer-banner.module';
import { ContactsPageComponent } from './contacts-page.component';
import { BlockEmailPhoneCardComponent } from './components/block-email-phone-card/block-email-phone-card.component';
import { BlockSnsCardComponent } from './components/block-sns-card/block-sns-card.component';
import { SectionFormComponent } from './components/section-form/section-form.component';

@NgModule({
  declarations: [
    ContactsPageComponent,
    BlockEmailPhoneCardComponent,
    BlockSnsCardComponent,
    SectionFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    ImageTitleModule,
    SectionHeaderGapModule,
    SectionFooterBannerModule,
  ],
})
export class ContactsPageModule {}
