import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ROUTE_PATH_FAQ } from '@studiobuki/web-core/lib/routing';

@Component({
  selector: 'app-section-form',
  templateUrl: './section-form.component.html',
  styleUrls: ['./section-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionFormComponent {
  public readonly ROUTE_PATH_FAQ = ROUTE_PATH_FAQ;

  // constructor() {}
}
