<button
  *ngIf="visible$ | async"
  class="container"
  (click)="onClick()"
  @fade
>
  <svg-icon
    class="container__icon"
    src="assets/images/top.svg"
    [svgStyle]="{
      width: '100%',
      height: '100%'
    }"
  ></svg-icon>
</button>
