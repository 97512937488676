import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { PriceModule } from '@studiobuki/web-core/lib/price';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { NavigationModule } from '@studiobuki/web-core/lib/navigation';
import { CoverCardComponent } from './components/cover-card/cover-card.component';
import { CoverPageComponent } from './cover-page.component';

@NgModule({
  declarations: [CoverPageComponent, CoverCardComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    PriceModule,
    SwiperModule,
    ButtonModule,
    NavigationModule,
  ],
})
export class CoverPageModule {}
