import type { TBookReviews } from '@shared/common/data/books/reviews/types';
import Froebelkan from './Froebelkan';

const Books = [Froebelkan];

export const Reviews = Books.reduce<TBookReviews>((arr, book) => {
  arr.push(...book.reviews);

  return arr;
}, []);

export default Books;
