import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-section-summary-item',
  templateUrl: './section-summary-item.component.html',
  styleUrls: ['./section-summary-item.component.scss'],
})
export class SectionSummaryItemComponent {
  @Input() key: string | undefined;

  @Input() value: string | undefined;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<string>;

  @ViewChild('template') public templateRef?: TemplateRef<HTMLElement>;

  constructor() {
    this.change = new EventEmitter();
  }

  onChange() {
    this.change.emit(this.key);
  }
}
