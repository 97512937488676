import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';

@Component({
  selector: 'app-toggle-same-address',
  templateUrl: './toggle-same-address.component.html',
  styleUrls: ['./toggle-same-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSameAddressComponent {
  @Input() set active(status: boolean) {
    if (status !== this.active) {
      this.active$.next(status);
    }
  }

  get active() {
    return this.active$.value;
  }

  public active$ = new BehaviorSubject(false);

  @Output() activeChange = this.active$.pipe(skip(1), distinctUntilChanged());

  enable() {
    this.active = true;
  }

  disable() {
    this.active = false;
  }
}
