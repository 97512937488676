<app-section-header-gap></app-section-header-gap>
<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__header">
        <h1 class="title">表紙のタイプを選びましょう</h1>
      </div>
      <div class="container__content">
        <div class="content">
          <div
            *ngFor="let _cover of coversArr"
            class="content__section"
          >
            <app-cover-card
              class="card-cover"
              [active]="_cover.id === cover"
              [recommended]="_cover.recommended"
              [carousel]="_cover.carousel"
              [title]="_cover.name"
              [price]="_cover.price"
              [summary]="_cover.summary"
              [buttonText]="_cover.buttonText"
              [buttonTextActive]="_cover.buttonTextActive"
              (buttonClick)="selectCover(_cover.id)"
            ></app-cover-card>
          </div>
        </div>
      </div>
      <div class="container__footer">
        <lib-navigation
          nextText="次に進む"
          (backClick)="onBackClick()"
          (nextClick)="onNextClick()"
        ></lib-navigation>
      </div>
    </div>
  </div>
</div>
