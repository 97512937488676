import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { BookFormModule } from '@studiobuki/web-core/lib/book-form';
import { BookFormDialogComponent } from './book-form-dialog.component';

@NgModule({
  declarations: [BookFormDialogComponent],
  imports: [CommonModule, MatDialogModule, BookFormModule],
  exports: [BookFormDialogComponent, MatDialogModule],
})
export class BookFormDialogModule {}
