<div class="container">
  <!-- <div class="container__title">
    <app-image-title type="faq">よくある質問</app-image-title>
  </div> -->
  <div class="container__content">
    <!-- <lib-faq-group [data]="faqData"></lib-faq-group> -->
    <lib-section-faq
      [faqData]="faqData"
      (buttonClick)="onButtonClick()"
      [condensed]="true"
    ></lib-section-faq>
  </div>
  <!-- <div
    *ngIf="condensed"
    class="container__button"
  >
    <lib-button
      color="accent-2"
      size="sm"
      (buttonClick)="onButtonClick()"
    ><b>全てのFAQをみる</b></lib-button>
  </div> -->
</div>
