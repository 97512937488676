<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
  ></app-section-header-gap>
  <lib-section-faq
    [faqData]="faqData"
    class="wrap__section-faq"
  ></lib-section-faq>
  <!-- <app-section-footer-banner
    class="wrap__section-footer-banner"
  ></app-section-footer-banner> -->
  <!-- <i class="wrap__dino"></i> -->
</div>
