import type { TSectionSteps } from '@studiobuki/web-core/lib/section-steps';

export const stepsData: TSectionSteps = [
  {
    imgSrc: 'assets/images/step1.png',
    text: ['お子さまの名前や誕生日を入力すると、絵本が', 'できあがります。'],
  },
  {
    imgSrc: 'assets/images/step2.png',
    text: [
      '試し読みをしながら、写真やメッセージな',
      'どカスタマイズ！これで世界で１冊の絵本',
      'の完成です！',
    ],
  },
  {
    imgSrc: 'assets/images/step3.png',
    text: ['ご注文確定後、印刷・製本してご自', '宅等にお届け！'],
  },
];
