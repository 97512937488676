import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InputFieldModule } from '@studiobuki/web-core/lib/input-field';
import { InputModule } from '@studiobuki/web-core/lib/input';
import { BookFormModule } from '@studiobuki/web-core/lib/book-form';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderModule } from '@studiobuki/web-core/lib/loader';
import { SectionFooterModule } from '@studiobuki/web-core/lib/section-footer';
import {
  FirebaseService as _FirebaseService,
  FIREBASE_CONFIG,
  FIREBASE_LOCAL_BACKEND,
} from '@studiobuki/web-core/lib/firebase';
import { ProductionService as _ProductionService } from '@studiobuki/web-core/lib/production';
import { environment } from 'src/environments/environment';
import { STRIPE_KEY } from '@studiobuki/web-core/lib/stripe';
import { YOTPO_APP_KEY } from '@studiobuki/web-core/lib/yotpo';
import { CustomBreakPointsProvider } from '@studiobuki/web-core/lib/media';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  TOKEN_PRODUCTION_CHECK_REGION_BY_NAME_ENGLISH,
  TOKEN_PRODUCTION_DEFAULT_REGION,
  TOKEN_PRODUCTION_DEFAULT_SHIPPING,
  TOKEN_PRODUCTION_GET_REGION_BY_NAME_ENGLISH,
  TOKEN_PRODUCTION_TIME_ZONE,
  TOKEN_VENDOR,
} from '@studiobuki/web-core';
import { PRODUCTION_TIME_ZONE } from '@shared/jp/production/constants';
import { EVendor } from '@shared/jp/enums';
import { APP_BASE_HREF } from '@angular/common';
import { BOOK_ALIAS_FROEBELKAN } from '@shared/jp/book/constants';
import { USER_PROJECT_BOOKS } from '@studiobuki/web-core/lib/user';
import { SHIPPING_METHOD_PREMIUM } from '@shared/common/shipping/constants';
import { REGION_NAME_ENGLISH_DEFAULT } from '@shared/jp/shipping/constants';
import {
  getRegionByNameEnglish,
  checkRegionNameEnglish,
} from '@shared/jp/shipping/utils';
import { ProductionService } from './services/production.service';
import { FirebaseService } from './services/firebase.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageModule } from './pages/home-page/home-page.module';
import { NotFoundPageModule } from './pages/not-found-page/not-found-page.module';
import { HeaderModule } from './components/header/header.module';
import { ContactsPageModule } from './pages/contacts-page/contacts-page.module';
import { FaqPageModule } from './pages/faq-page/faq-page.module';
import { AboutPageModule } from './pages/about-page/about-page.module';
import { PolicyPageModule } from './pages/policy-page/policy-page.module';
import { TermsPageModule } from './pages/terms-page/terms-page.module';
import { CoverPageModule } from './pages/cover-page/cover-page.module';
import { CartPageModule } from './pages/cart-page/cart-page.module';
import { OrderPageModule } from './pages/order-page/order-page.module';
import { OrdercontractPageModule } from './pages/ordercontract-page/ordercontract-page.module';
import { GiftBuyPageModule } from './pages/gift-buy-page/gift-buy-page.module';
import { GiftBuySpecialPageModule } from './pages/gift-buy-special-page/gift-buy-special-page.module';
import { GiftOrderPageModule } from './pages/gift-order-page/gift-order-page.module';
import { GiftThanksPageModule } from './pages/gift-thanks-page/gift-thanks-page.module';
import { GiftUsePageModule } from './pages/gift-use-page/gift-use-page.module';
import { ReviewsPageModule } from './pages/reviews-page/reviews-page.module';
import { BASE_HREF } from './app.contants';
import { AuthPageModule } from './pages/auth-page/auth-page.module';
import { OrdercontractGiftPageModule } from './pages/ordercontract-gift-page/ordercontract-gift-page.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    FlexLayoutModule,
    BrowserModule,
    AppRoutingModule,
    InputFieldModule,
    InputModule,
    BookFormModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    BrowserAnimationsModule,
    LoaderModule,
    SectionFooterModule,
    HomePageModule,
    AboutPageModule,
    PolicyPageModule,
    TermsPageModule,
    OrdercontractPageModule,
    OrdercontractGiftPageModule,
    ContactsPageModule,
    FaqPageModule,
    NotFoundPageModule,
    CoverPageModule,
    CartPageModule,
    OrderPageModule,
    GiftBuyPageModule,
    GiftBuySpecialPageModule,
    GiftOrderPageModule,
    GiftThanksPageModule,
    GiftUsePageModule,
    ReviewsPageModule,
    AuthPageModule,
    HeaderModule,
  ],
  providers: [
    CustomBreakPointsProvider,
    { provide: LOCALE_ID, useValue: 'ja' },
    { provide: APP_BASE_HREF, useValue: BASE_HREF },
    {
      provide: FIREBASE_CONFIG,
      useValue: environment.firebaseConfig,
    },
    {
      provide: FIREBASE_LOCAL_BACKEND,
      useValue: environment.localBackend,
    },
    {
      provide: USER_PROJECT_BOOKS,
      useValue: [BOOK_ALIAS_FROEBELKAN],
    },
    {
      provide: STRIPE_KEY,
      useValue: environment.production
        ? 'pk_live_51HbeSXHbWEBrAyqZQjAVCysf1RHc1xVYkpnTsKxI0lxvjyURUuyLrSHAUR0o2cNz99lwKQe9i086TAwdYm2GX1xh00v4424a4D'
        : 'pk_test_51HbeSXHbWEBrAyqZIQtWf2VTT9gRBg10xwWoaVn9HYSJhnUxcwy3v9UyDXMXVrSCexPWiPC0KD5RKzohOxMxgH1E00Ra6bMRAv',
    },
    // * OPTIONAL
    // {
    //   provide: STRIPE_ELEMENTS_OPTIONS,
    //   useValue: {},
    // },
    {
      provide: YOTPO_APP_KEY,
      useValue: 'hfBplQ2C1X81kzBkWVwiVVv4Cp1hvQ9yTJoGPJhn',
    },
    {
      provide: TOKEN_VENDOR,
      useValue: EVendor.froebelkan,
    },
    {
      provide: TOKEN_PRODUCTION_TIME_ZONE,
      useValue: PRODUCTION_TIME_ZONE,
    },
    {
      provide: TOKEN_PRODUCTION_DEFAULT_REGION,
      useValue: REGION_NAME_ENGLISH_DEFAULT,
    },
    {
      provide: TOKEN_PRODUCTION_DEFAULT_SHIPPING,
      useValue: SHIPPING_METHOD_PREMIUM,
    },
    {
      provide: TOKEN_PRODUCTION_GET_REGION_BY_NAME_ENGLISH,
      useValue: getRegionByNameEnglish,
    },
    {
      provide: TOKEN_PRODUCTION_CHECK_REGION_BY_NAME_ENGLISH,
      useValue: checkRegionNameEnglish,
    },
    {
      provide: _FirebaseService,
      useExisting: FirebaseService,
    },
    {
      provide: _ProductionService,
      useExisting: ProductionService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
