<lib-card title="メール・電話で問い合わせる">
  <div class="container">
    <div class="container__title">
      <p class="title">
        <a
          href="mailto:yay@hellobuki.com"
          class="title__link"
          >yay@hellobuki.com</a
        >
      </p>
    </div>
    <div class="container__subtitle">
      <p class="subtitle">
        <a
          href="tell:080-7652-1468"
          class="subtitle__link"
          >080-7652-1468</a
        >
        <span class="subtitle__text">（平日10~16時）</span>
      </p>
    </div>
    <hr class="container__hr" />
    <div class="container__text">
      <p class="text">
        ※「@ezweb.ne.jp」などのキャリアメールは、設定によりこちらからの返信が受信できない場合があります。
      </p>
    </div>
    <div class="container__text">
      <p class="text">
        ※3営業日以内に返信がない場合は、こちらからの返信が迷惑メールとなり受信できていないかもしれません。違う方法でお問い合わせください。
      </p>
    </div>
  </div>
</lib-card>
