import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { NavigationStart, Router } from '@angular/router';
import {
  INPUT_NAME_AGE_TO_BE,
  INPUT_NAME_KIDS_BIRTHDAY,
  INPUT_NAME_KIDS_NAME,
} from '@shared/common/book/constants';
import { INPUT_NAME_KUN_CHAN } from '@shared/jp/book/constants';
import { ALIAS_TO_ID_MAP } from '@shared/jp/book/maps';
import type { IBookInputNameToValueMap } from '@shared/common/data/books/inputs/types';
import { checkUserGift } from '@shared/jp/gift/functions/checks';
import { Logger } from '@shared/common/logger';
import type { ICommonBookData } from '@shared/jp/models';
import type { BookFormComponent } from '@studiobuki/web-core/lib/book-form';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ROUTE_QUERY_PARAM_GIFT } from '@studiobuki/web-core/lib/routing';
import { filter, take } from 'rxjs/operators';
import { GAGetEvents, GATrack } from 'src/analytics';
import { BookFroebelkanService } from 'src/app/pages/book-page/pages/book-froebelkan-page/book-froebelkan.service';
import type { TBookAlias } from '@shared/jp/book/interfaces';

const log = new Logger('BookFormDialogComponent');

@Component({
  selector: 'app-book-form-dialog',
  templateUrl: './book-form-dialog.component.html',
  styleUrls: ['./book-form-dialog.component.scss'],
})
export class BookFormDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      alias: BookFormComponent<TBookAlias>['alias'];
      inputs: BookFormComponent<TBookAlias>['inputs'];
      gift: string;
    },
    private _dialogRef: MatDialogRef<ThisType<BookFormDialogComponent>>,
    private _router: Router,
    private _firebaseService: FirebaseService,
    private _bookFroebelkanService: BookFroebelkanService,
  ) {
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        take(1),
      )
      .subscribe(() => {
        this._dialogRef.close();
      });
  }

  async onSubmit(value: IBookInputNameToValueMap) {
    const { alias, gift } = this.data;

    const common: Partial<ICommonBookData> = {};

    // if (gift) {
    common.gift = gift;

    const userGift = await this._firebaseService.getUserGift(gift);

    if (checkUserGift(userGift)) {
      common.cover = userGift.book.cover;
    }
    // }

    // const newBookId = await this._booksService.createBookByAlias(
    //   alias,
    //   value,
    //   common,
    // );

    const newBookId = await this._bookFroebelkanService.createBook(
      {
        // @ts-ignore
        heroName: value[INPUT_NAME_KIDS_NAME],
        // @ts-ignore
        kunChan: value[INPUT_NAME_KUN_CHAN],
        // @ts-ignore
        birthDate: value[INPUT_NAME_KIDS_BIRTHDAY],
        // @ts-ignore
        ageToBe: value[INPUT_NAME_AGE_TO_BE],
      },
      common,
    );

    log.info('created book', newBookId);

    await this._router.navigate(['book', newBookId], {
      // replaceUrl: true,
      queryParams: {
        [ROUTE_QUERY_PARAM_GIFT]: gift,
      },
    });

    log.info('submit', alias, value);

    const bookAliasId = ALIAS_TO_ID_MAP[alias];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!bookAliasId) {
      log.error(`didn't manage to get bookAliasId by alias`, {
        alias,
        bookAliasId,
      });
      return;
    }

    GATrack(GAGetEvents.getBookPreview(bookAliasId));
  }
}
