<app-default
  [id]="id"
  [title]="title"
  [subtitle]="subtitle"
  [specs]="specs"
  [price]="price"
  [photo]="photo"
  [discount]="discount"
  *ngIf="media.isDesktop$ | async; else mobile"
></app-default>

<ng-template #mobile>
  <app-mobile
    [id]="id"
    [title]="title"
    [subtitle]="subtitle"
    [specs]="specs"
    [price]="price"
    [photo]="photo"
    [discount]="discount"
  ></app-mobile>
</ng-template>
