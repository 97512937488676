<div class="wrap">
  <app-section-header-gap></app-section-header-gap>
  <div class="wrap__container">
    <div class="container">
      <h1 class="container__title">お探しのページが見つかりませんでした</h1>
      <lib-button
        class="container__button"
        (buttonClick)="onHomeButtonClick()"
        [fullWidth]="true"
        >HOME</lib-button
      >
    </div>
  </div>
  <!-- center alignment compenstaion -->
  <!-- <app-section-header-gap></app-section-header-gap> -->
</div>
