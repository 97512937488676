import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionTextModule } from '@studiobuki/web-core/lib/section-text';
import { PolicyPageComponent } from './policy-page.component';

@NgModule({
  declarations: [PolicyPageComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    SectionTextModule,
    // SectionFooterBannerModule,
  ],
})
export class PolicyPageModule {}
