import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { INavigationItemChild } from '../../types';

@Component({
  selector: 'app-navigation-dropdown-item',
  templateUrl: './navigation-dropdown-item.component.html',
  styleUrls: ['./navigation-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDropdownItemComponent {
  @Input() imgSrc?: INavigationItemChild['imgSrc'];

  @Input() link?: INavigationItemChild['link'];

  // constructor() {}
}
