<div class="container">
  <div class="container__logo">
    <a
      routerLink="/"
      class="logo"
    ></a>
  </div>
  <app-steps
    *ngIf="steps && step && step <= steps.length"
    class="container__steps"
    [steps]="steps"
    [step]="step"
  ></app-steps>
</div>
