import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionTextModule } from '@studiobuki/web-core/lib/section-text';
import { OrdercontractPageComponent } from './ordercontract-page.component';

@NgModule({
  declarations: [OrdercontractPageComponent],
  imports: [CommonModule, SectionHeaderGapModule, SectionTextModule],
})
export class OrdercontractPageModule {}
