<app-default
  [id]="id"
  [title]="title"
  [subtitle]="subtitle"
  [specs]="specs"
  [price]="price"
  [discount]="discount"
  [photo]="photo"
  (close)="onClose()"
  *ngIf="media.isDesktop$ | async; else mobile"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</app-default>

<ng-template #mobile>
  <app-mobile
    [id]="id"
    [title]="title"
    [subtitle]="subtitle"
    [specs]="specs"
    [price]="price"
    [discount]="discount"
    [photo]="photo"
    (close)="onClose()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </app-mobile>
</ng-template>

<ng-template #content>
  <div class="content">
    <app-edit
      class="content__item"
      [link]="'/book/' + id"
      >絵本の内容を編集する</app-edit
    >
    <app-edit
      class="content__item"
      [link]="'/cover/' + id"
      >表紙のタイプを変更する</app-edit
    >
    <div class="content__item">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
