import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DialogsModule } from '@studiobuki/web-core/lib/dialogs';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { CardModule } from '@studiobuki/web-core/lib/card';
import { GiftOrderPageComponent } from './gift-order-page.component';
import { MainModule } from './components/main/main.module';
// import { FooterModule } from './components/footer/footer.module';
import { SectionLeftComponent } from './components/section-left/section-left.component';
import { SectionRightComponent } from './components/section-right/section-right.component';
import { FinalComponent } from './components/final/final.component';
import { SectionFullComponent } from './components/section-full/section-full.component';
import { BlockSnsCardComponent } from './components/block-sns-card/block-sns-card.component';
import { HeaderModule } from '../order-page/components/header/header.module';
import { FooterModule } from '../order-page/components/footer/footer.module';
import { AsideModule } from '../order-page/components/aside/aside.module';

@NgModule({
  declarations: [
    GiftOrderPageComponent,
    SectionLeftComponent,
    SectionRightComponent,
    FinalComponent,
    SectionFullComponent,
    BlockSnsCardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    MainModule,
    FooterModule,
    AsideModule,
    AngularSvgIconModule,
    DialogsModule,
    ButtonModule,
    CardModule,
  ],
})
export class GiftOrderPageModule {}
