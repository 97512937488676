import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductRadioCardModule } from '@studiobuki/web-core/lib/product-radio-card';
import { FormShipFromComponent } from './form-ship-from.component';

@NgModule({
  declarations: [FormShipFromComponent],
  imports: [CommonModule, ProductRadioCardModule],
  exports: [FormShipFromComponent],
})
export class FormShipFromModule {}
