<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <lib-image-title type="cart">ご注文のギフトコード</lib-image-title>
      </div>
      <div class="container__content">
        <div class="content">
          <div
            class="content__description"
            [innerHTML]="description"
          ></div>
          <div class="content__price">
            <lib-price
              [price]="price.value"
              [currency]="price.currency"
              [shippingIncluded]="price.shippingIncluded"
              [discount]="discount"
              [shippingDiscount]="shippingDiscount"
              size="xl"
            ></lib-price>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
