import {
  INPUT_NAME_AGE_TO_BE,
  INPUT_NAME_KIDS_BIRTHDAY,
  INPUT_NAME_KIDS_NAME,
  INPUT_VALUE_COVER_TYPE_HARD,
  INPUT_VALUE_COVER_TYPE_SOFT,
} from '@shared/common/book/constants';
import {
  BOOK_ALIAS_FROEBELKAN,
  BOOK_PRICE_FROEBELKAN,
  BOOK_PRICE_FROEBELKAN_HARD_COVER,
  INPUT_NAME_CHARACTERS,
  INPUT_NAME_KUN_CHAN,
  INPUT_VALUE_KUN_CHAN_NASHI,
} from '@shared/jp/book/constants';
import { charactersFroebelkanArr, kunChanArr } from '@shared/jp/book/data';
import { KUNCHAN_VALUE_TO_LABEL_MAP } from '@shared/jp/book/maps';
import { EBookBannerTextColor } from '@shared/common/data/books/banner/types';
import { EBookDescriptionTextItemType } from '@shared/common/data/books/desicription/types';
import { getBookDateInputData } from '@shared/common/data/books/inputs/date/utils';
import {
  getBookSelectInputData,
  getBookSelectInputOptionData,
} from '@shared/common/data/books/inputs/select/utils';
import {
  getBookMultiselectInputData,
  getBookMultiselectInputOptionData,
} from '@shared/common/data/books/inputs/multiselect/utils';
import { getBookTextInputData } from '@shared/common/data/books/inputs/text/utils';
import { getBookNameValidatorList } from '@shared/jp/data/books/inputs/validators';
import { GET_BOOK_NAME_ERROR } from '@shared/jp/data/books/inputs/validators.constants';
import { getBookPriceData } from '@shared/jp/data/books/prices/utils';
import {
  PURPOSE_ADVENTURE,
  PURPOSE_BIRTH_GIFT,
} from '@shared/common/data/books/properties/constants';
import type { IBook } from '@shared/jp/data/books/types';
import { getFilledNumbersArray } from '@studiobuki/web-core/lib/timepicker-select';
import type { TCharactersF } from '@shared/jp/book/interfaces';
import { getCoverHard, getCoverSoft } from './_utils';

// TODO: import from shared
export const MAKE_MAP =
  <K extends string, V = string>() =>
  <T extends { readonly [key in K]: V }>(o: T) =>
    o;

export const FroebelkanInputsKidsName = getBookTextInputData({
  label: '子どもの下の名前',
  placeholder: 'ひらがな６文字まで',
  name: INPUT_NAME_KIDS_NAME,
  validator: getBookNameValidatorList(6, 2),
  error: GET_BOOK_NAME_ERROR(6),
} as const);

export const FroebelkanInputsKunChan = getBookSelectInputData({
  label: 'くん・ちゃん付け',
  placeholder: '選んでください',
  options: kunChanArr.map((value) =>
    getBookSelectInputOptionData({
      label: KUNCHAN_VALUE_TO_LABEL_MAP[value],
      value,
    }),
  ),
  value: INPUT_VALUE_KUN_CHAN_NASHI,
  name: INPUT_NAME_KUN_CHAN,
} as const);

export const FroebelkanInputsKidsBirthday = getBookDateInputData({
  label: '生年月日',
  placeholder: 'yyyy/mm/dd',
  // value: new Date(),
  max: new Date(),
  name: INPUT_NAME_KIDS_BIRTHDAY,
} as const);

export const FroebelkanInputsAgeToBe = getBookSelectInputData({
  label: '何歳の誕生日？',
  placeholder: '1～6歳',
  options: getFilledNumbersArray(1, 6, 1).map((year) =>
    getBookSelectInputOptionData({ label: `${year}歳`, value: year }),
  ),
  // value: '',
  name: INPUT_NAME_AGE_TO_BE,
} as const);

const characterFroebelkanLabelMap = MAKE_MAP<TCharactersF>()({
  C01: 'アンパンマン',
  C02: 'しょくぱんまん',
  C03: 'カレーパンマン',
  C04: 'メロンパンナちゃん',
  C05: 'バタコさん',
  C06: 'めいけんチーズ',
} as const);

export const FroebelkanInputsCharacters = getBookMultiselectInputData({
  // label: 'LABEL',
  options: charactersFroebelkanArr.map((character) =>
    getBookMultiselectInputOptionData({
      imgSrc: `assets/images/f-characters/${character}.png`,
      label: characterFroebelkanLabelMap[character],
      value: character,
    }),
  ),
  name: INPUT_NAME_CHARACTERS,
  // set inside
  // min: 3,
  // max: 3,
});

const prices = {
  [INPUT_VALUE_COVER_TYPE_SOFT]: getBookPriceData(BOOK_PRICE_FROEBELKAN),
  [INPUT_VALUE_COVER_TYPE_HARD]: getBookPriceData(
    BOOK_PRICE_FROEBELKAN_HARD_COVER,
  ),
} as const;

const coverSoft = getCoverSoft({
  price: prices[INPUT_VALUE_COVER_TYPE_SOFT],
  carousel: [
    'assets/images/covers/froebelkan/SC01.jpg',
    'assets/images/covers/froebelkan/SC02.jpg',
    'assets/images/covers/froebelkan/SC03.jpg',
  ],
});
const coverHard = getCoverHard({
  price: prices[INPUT_VALUE_COVER_TYPE_HARD],
  carousel: [
    'assets/images/covers/froebelkan/HC01.jpg',
    'assets/images/covers/froebelkan/HC02.jpg',
    'assets/images/covers/froebelkan/HC03.jpg',
  ],
});

const Froebelkan: IBook = {
  caption: 'アンパンマンといっしょに<wbr />お誕生日をお祝い！',
  summary: [
    'お子さんのお誕生日をお祝いするためにアンパンマンやなかまたちがかけつけます。スペシャルパーティーの始まりです！',
  ],
  alias: BOOK_ALIAS_FROEBELKAN,
  prices: [
    [INPUT_VALUE_COVER_TYPE_SOFT, prices[INPUT_VALUE_COVER_TYPE_SOFT]],
    [INPUT_VALUE_COVER_TYPE_HARD, prices[INPUT_VALUE_COVER_TYPE_HARD]],
  ],
  photos: [
    'assets/images/carousel01.jpg',
    'assets/images/carousel02.jpg',
    'assets/images/carousel03.jpg',
    'assets/images/carousel04.jpg',
    'assets/images/carousel05.jpg',
  ],
  menuPhoto: 'assets/images/f-sample.jpg',
  previewPhoto: 'assets/images/f-sample.jpg',
  // TODO
  properties: {
    purpose: [PURPOSE_ADVENTURE, PURPOSE_BIRTH_GIFT],
    age: {
      start: 0,
      end: 3,
    },
  },
  notice: {
    caption:
      '成長の記録や思い出の写真、メッセー ジとともにお子さま一人ひとりにパーソナライズされた絵本！',
    description:
      'みんなが大好きなアンパンマンやなかまたちがたくさん登\n場。スペシャルパーティーの始まりです！',
    points: [
      {
        imgSrc: 'assets/images/welcome-notice-point-1.png',
        caption: '1〜6歳におすすめ',
      },
      {
        imgSrc: 'assets/images/welcome-notice-point-2.png',
        caption: 'お誕生日プレゼントに',
      },
    ],
  },
  points: [
    {
      caption: 'どんなカスタムができるの？',
      description:
        '・主人公の名前をいれる<br>・表紙の色やリボンの色を選べる<br>・お子さんの成長記録（身長・体重）をいれる<br>・写真をいれる（５枚）<br>・ケーキのロウソクの絵柄を選べる<br> ・メッセージをいれる（最大２か所）',
    },
    {
      caption: '絵本のストーリーは？',
      description:
        'アンパンマン、ばいきんまん、しょくぱんまん、メロンパンナちゃんなど人気のアンパンマンキャラクターが勢揃い。お子さんのバースデーパーティーの準備をします。<br>一生の思い出に残るバースデーパーティーの始まりです。',
    },
    {
      caption: 'ページ数や絵本の大きさは？',
      description:
        '全ての絵本は1冊ずつ、ご注文を受けてからオーダーメイドで印刷・製本されます。高級感のある、環境に配慮した用紙を使用しています。<br><br><b>・全28ページ<br>・21cm×21cm（本文部分）の正方形<br>・日本国内で製造</b>',
    },
  ],
  inputs: [
    FroebelkanInputsKidsName,
    FroebelkanInputsKunChan,
    FroebelkanInputsKidsBirthday,
    FroebelkanInputsAgeToBe,
  ],
  banner: {
    imgSrc: 'assets/images/bk3-banner-desktop-JP.jpg',
    imgSrcLtMd: 'assets/images/bk3-banner-mobile-JP.jpg',
    text: 'まいにちが　だいぼうけん。　ここにある　まほうを　みつけよう。',
    textColor: EBookBannerTextColor.dark,
  },
  description: [
    {
      imgSrc: 'assets/images/image01.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="font-family: ZenMaruGothic">伝えたい思いを込めて</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'お子さまに伝えたいメッセージを入れ、何度でも読み返したくなります。',
        },
      ],
    },
    {
      imgSrc: 'assets/images/image02.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="font-family: ZenMaruGothic">好きな写真を選んで絵本に</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,

          text: `お子さまの写真、家族との写真、お子さまが描いた絵を写真で撮ったものなど、思い出として残すことができます。`,
        },
      ],
    },
    {
      imgSrc: 'assets/images/image03.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="font-family: ZenMaruGothic">最短4日でお届け</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: '配送は宅急便、またはメール便（ポスト投函、日時指定不可）で、ご自宅までお届けします。発送時にメールでご案内いたします。',
        },
      ],
    },
    {
      imgSrc: 'assets/images/image04.jpg',
      text: [
        {
          type: EBookDescriptionTextItemType.caption,
          text: /* html */ `<span style="font-family: ZenMaruGothic">ギフトにも！</span>`,
        },
        {
          type: EBookDescriptionTextItemType.regular,
          text: 'お孫さんのお誕生日に、お友だちのお子さまへのお祝いに、特別な思い出の贈り物になります。',
        },
      ],
    },
  ],
  review: {
    text: [
      'アンパンマンが大好きな娘へのプレゼントに。自分の写真を見て大興奮！いい思い出になりました。',
    ],
  },
  reviews: [
    {
      photoImgSrc: 'assets/images/FK_AP_review1.png',
      text: [
        '絵本には写真やアンパンマンたちがたくさんで、ひなちゃんも嬉しそう!\n' +
          '寝る前まで何回もこれ読んでって持ってきて、お気に入りの1冊になりました🎀\n' +
          '心があったまる内容で絵もかわいくて癒されるので、ママやパパにとっても大切な宝物です。',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review2.png',
      text: [
        'アンパンマンデザインの箱に大興奮 ... ！\n' +
          '箱が飾り棚になるので作るのも楽しみです。\n' +
          'ロウソクのキャラクターや写真を一緒に選べて特別感があり、" はるくんの！" と大喜び♪\n' +
          '特に最後のページにあるやなせたかし先生の温かいお言葉が好きです😌\n' +
          '\n' +
          'ㅤㅤㅤㅤㅤ\n',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review3.png',
      text: [
        '最近アンパンマンにハマってる娘はすぐに見つけて指さしてたよ🤭\n' +
          '身長を入れられるところになると「なんだろう？」と眺めていたから頭触って身長のことだよって教えてあげたら自分の頭ポンポンして理解したみたい🤭\n' +
          'ママは懐かしみながら、娘は自分のことを知りながら読めて素敵でした🎂',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review4.jpeg',
      text: [
        '絵本には出生時間や身長体重、メッセージも入れられて、ずっと記念に残る1冊になります！WEBで入力だからプレゼントにもいいかも！\n' +
          '何枚も写真が入れられるのもすごい嬉しいポイントでした！！\n' +
          '一緒に懐かしみながら読める日が楽しみ♪\n',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review5.png',
      text: [
        'スマホ1台でとっても簡単に作れちゃいます😳\n' +
          '子どもの記録やお気に入りの写真を入れるだけで思い出に残る1冊が出来上がります✨\n' +
          '\n' +
          'お孫さんなどへのプレゼントにもぴったりの絵本ですよ😊',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review6.png',
      text: [
        '写真を選ぶと絵本のプレビューが見れます📖\n' +
          '「このページは絶対にこの写真がいい！」\n' +
          '「やっぱり他の写真がいいかな？」\n' +
          'と悩むのも楽しかったです😊\n' +
          '\n' +
          'いつか、この本を手にとって\n' +
          '「3歳の誕生日にもらった絵本だ」\n' +
          '「あの頃はアンパンマンが好きだったな」\n' +
          'と思い出してくれたらうれしいです。',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review7.png',
      text: [
        '絵本の内容もすごくボリュームがあるし、登場するキャラクターも選べるところがあるよ！\n' +
          '\n' +
          'ギフトコードとして贈ることもできるんだって❣️\n' +
          '\n' +
          '親子でこの絵本は宝物になりました！\n' +
          'とっても素敵な3歳の誕生日プレゼントになって\n' +
          'うれしいです😌💕',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review8.png',
      text: [
        '絵も文章も素敵すぎる！\n' +
          '何年経っても絵本は愛され続けるもの。\n' +
          '写真が入れられるので、アルバム代わりになって素敵でした😌',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review9.jpeg',
      text: [
        '思い出になる絵本にひーちゃんも大喜び😆\n' +
          'お兄ちゃんの誕生日にもプレゼントしようかな！\n' +
          '\n' +
          '成長が一目で分かるし、メッセージも絵本の中に入れられるから一生大切にしたい🎁',
      ],
    },
    {
      photoImgSrc: 'assets/images/FK_AP_review10.png',
      text: [
        '世界にひとつだけの絵本 📖\n' +
          '\n' +
          '写真入れたりメッセージ入れたりできて作らせてもらって すぐ届いた 🚚📦\n' +
          '\n' +
          '自分の写真や、おとの大好きなアンパンマンがいっぱいで嬉しそうにみてました😌',
      ],
    },
  ],
  covers: {
    [INPUT_VALUE_COVER_TYPE_HARD]: coverHard,
    [INPUT_VALUE_COVER_TYPE_SOFT]: coverSoft,
  },
  cover: INPUT_VALUE_COVER_TYPE_SOFT,
};

export default Froebelkan;
