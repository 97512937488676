import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaService } from '@studiobuki/web-core/lib/media';
import {
  ROUTE_PATH_PARAM_GIFT_ID,
  ROUTE_PATH_PARAM_ORDER_ID,
} from '@studiobuki/web-core/lib/routing';
import { Subscription } from 'rxjs';
import { RoutingService } from 'src/app/services/routing.service';

@Component({
  templateUrl: './gift-thanks-page.component.html',
  styleUrls: ['./gift-thanks-page.component.scss'],
})
export class GiftThanksPageComponent implements OnInit, OnDestroy {
  private _sub = new Subscription();

  public orderId?: string;

  public giftId?: string;

  constructor(
    private _route: ActivatedRoute,
    public routing: RoutingService,
    public media: MediaService,
  ) {}

  ngOnInit(): void {
    this._sub.add(
      this._route.queryParams.subscribe((params) => {
        this.orderId = params[ROUTE_PATH_PARAM_ORDER_ID];
        this.giftId = params[ROUTE_PATH_PARAM_GIFT_ID];
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
