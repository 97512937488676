<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__section container__section_left">
        <div class="content">
          <div class="content__section content__section_products">
            <ng-container
              *ngIf="
                (discountService.activeDiscountCampaign$ | async) === undefined
              "
            >
              <app-product
                class="product"
                *ngFor="let p of products$ | async"
                [id]="p.bookId"
                [title]="p.name"
                [subtitle]="p.cover.name"
                [specs]="p.specs"
                [price]="getProductPrice(p)"
                [photo]="p.image"
                (close)="removeProduct(p)"
              ></app-product>
            </ng-container>
            <ng-container
              *ngIf="
                discountService.activeDiscountCampaign$
                  | async as discountCampaign
              "
            >
              <app-product
                class="product"
                *ngFor="let p of products$ | async"
                [id]="p.bookId"
                [title]="p.name"
                [subtitle]="p.cover.name"
                [specs]="p.specs"
                [price]="getProductPrice(p)"
                [discount]="getBooksDiscount(discountCampaign, p.alias)"
                [photo]="p.image"
                (close)="removeProduct(p)"
              ></app-product>
            </ng-container>
          </div>
          <div
            class="content__section content__section_button"
            fxHide.lt-lg
          >
            <lib-button
              color="accent-2"
              (buttonClick)="addAnotherBookButtonClick()"
              [fullWidth]="true"
              >絵本を追加する</lib-button
            >
          </div>
          <div
            class="content__section content__section_payment"
            fxHide.lt-lg
          >
            <app-payments></app-payments>
          </div>
        </div>
      </div>
      <div class="container__section container__section_right">
        <div class="sidebar">
          <div
            *ngIf="calculatorItems$ | async as calculatorItems"
            class="sidebar__calculator"
          >
            <div class="calculator">
              <div class="calculator__component">
                <lib-calculator [items]="calculatorItems"></lib-calculator>
              </div>
              <p class="calculator__footer-text">
                *送料は発送方法や発送先で変わる場合があります
              </p>
            </div>
          </div>
          <div
            *ngIf="order$ | async as order"
            class="sidebar__coupon"
            fxHide.lt-lg
          >
            <ng-content
              *ngTemplateOutlet="couponTpl; context: { $implicit: order }"
            ></ng-content>
          </div>
          <div class="sidebar__button">
            <div class="proceed-btn">
              <p class="proceed-btn__header-text">
                <!-- 絵本の内容にお間違いはありませんか？
                <br />
                ご注文後はキャンセルできません -->
              </p>
              <div class="proceed-btn__component">
                <lib-button
                  [disabled]="!((isProceed$ | async) === true)"
                  [icons]="['arrow-right']"
                  (buttonClick)="proceed()"
                  [fullWidth]="true"
                  >お会計にすすむ</lib-button
                >
              </div>
              <p
                *ngIf="isLimitExceeded$ | async"
                class="proceed-btn__footer-text"
              >
                一度にカートに入れられるのは4冊までです。
              </p>
            </div>
          </div>
          <div
            class="sidebar__payment"
            fxHide.gt-md
          >
            <app-payments></app-payments>
          </div>
          <div
            class="sidebar__button"
            fxHide.gt-md
          >
            <lib-button
              color="accent-2"
              (buttonClick)="addAnotherBookButtonClick()"
              [fullWidth]="true"
              >絵本を追加する</lib-button
            >
          </div>
          <div
            *ngIf="order$ | async as order"
            fxHide.gt-md
            class="sidebar__coupon"
          >
            <ng-content
              *ngTemplateOutlet="couponTpl; context: { $implicit: order }"
            ></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template
  #couponTpl
  let-order
>
  <app-coupon
    [orderId]="order.orderId"
    [coupon]="(coupon$ | async) || undefined"
    (couponChange)="coupon$.next($event)"
  ></app-coupon>
</ng-template>
