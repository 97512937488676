<div style="margin: 30px">
  <!--<h2 mat-dialog-title>Delete Book</h2>-->
  <mat-dialog-content class="mat-typography">
    <p>絵本のデータが削除されますが、本当にいいですか？</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close
      cdkFocusInitial
    >
      もどる
    </button>
    <button
      mat-button
      [mat-dialog-close]="true"
    >
      はい
    </button>
  </mat-dialog-actions>
</div>
