<div class="wrap">
  <div class="wrap__main">
    <div class="main">
      <div class="main__photo">
        <app-photo
          [src]="photo"
          [alt]="title"
        ></app-photo>
      </div>
      <div class="main__content">
        <app-title
          [title]="title"
          [subtitle]="subtitle"
        ></app-title>
      </div>
    </div>
  </div>
  <div class="wrap__specs">
    <app-spec
      *ngFor="let key of specsKeys"
      [key]="key"
      [value]="specs[key] || ''"
    ></app-spec>
  </div>
  <div class="wrap__toggles">
    <ng-content></ng-content>
  </div>
  <div class="wrap__price">
    <lib-price
      [price]="price.value"
      [currency]="price.currency"
      [discount]="discount"
      [boldPrice]="true"
    ></lib-price>
  </div>
  <app-x-button
    class="wrap__x"
    (click)="onClose()"
  ></app-x-button>
</div>
