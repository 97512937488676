import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** NOTE: the parent element has to has a `position: relative;` */
@Component({
  selector: 'app-navigation-dropdown',
  templateUrl: './navigation-dropdown.component.html',
  styleUrls: ['./navigation-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDropdownComponent {
  @Input() active?: boolean | undefined;

  // constructor() {}
}
