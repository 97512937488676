export const environment = {
  production: true,
  localBackend: false,
  firebaseConfig: {
    apiKey: 'AIzaSyC5DV2N91bscYrJp_HNd3hWoZ0A0LgNsR8',
    authDomain: 'buki-st12020.firebaseapp.com',
    databaseURL: 'https://buki-st12020.firebaseio.com',
    projectId: 'buki-st12020',
    storageBucket: 'buki-st12020.appspot.com',
    messagingSenderId: '72176090670',
    appId: '1:72176090670:web:fc919a5b33ff31af0c69bf',
    measurementId: 'G-P53CM31YTN',
  },
};
