import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageTitleModule } from '@studiobuki/web-core/lib/image-title';
import { PriceModule } from '@studiobuki/web-core/lib/price';
import { SectionGiftCartComponent } from './section-gift-cart.component';

@NgModule({
  declarations: [SectionGiftCartComponent],
  imports: [CommonModule, ImageTitleModule, PriceModule],
  exports: [SectionGiftCartComponent],
})
export class SectionGiftCartModule {}
