<ng-template #content>
  <svg-icon
    *ngIf="svgIconSrc"
    class="menu-item__svg-icon"
    [src]="svgIconSrc"
    [applyClass]="true"
  ></svg-icon>
  <span
    *ngIf="title"
    class="menu-item__text"
    >{{ title }}</span
  >
  <i
    *ngIf="children.length"
    class="menu-item__dropdown-icon"
  ></i>
  <span
    *ngIf="(isDropdownActive | async) && children.length"
    class="menu-item__dropdown"
    @slideY
  >
    <span class="menu-dropdown">
      <a
        *ngFor="let child of children"
        [routerLink]="child.link"
        [queryParams]="child.queryParams || null"
        class="menu-dropdown__item"
        >{{ child.title }}</a
      >
    </span>
  </span>
  <!-- <app-menu-dropdown
    *ngIf="(isDropdownActive | async) && children.length"
    @fade
  >
    <app-menu-dropdown-item
      *ngFor="let child of children"
      [imgSrc]="child.imgSrc"
    >{{ child.title }}</app-menu-dropdown-item>
  </app-menu-dropdown> -->
</ng-template>

<a
  *ngIf="link"
  class="menu-item"
  [class.menu-item_small]="small"
  [routerLink]="link.split('#')[0]"
  [fragment]="$any(link.split('#')[1])"
  [target]="target"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a
  *ngIf="href"
  class="menu-item"
  [class.menu-item_small]="small"
  [href]="href"
  [target]="target"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<span
  *ngIf="!href && !link"
  class="menu-item"
  [class.menu-item_small]="small"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>
