import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { LogoModule } from 'src/app/components/logo/logo.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header.component';
import { StepsComponent } from './components/steps/steps.component';

@NgModule({
  declarations: [HeaderComponent, StepsComponent],
  imports: [CommonModule, AngularSvgIconModule, RouterModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
