import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionTextModule } from '@studiobuki/web-core/lib/section-text';
import { OrdercontractGiftPageComponent } from './ordercontract-gift-page.component';

@NgModule({
  declarations: [OrdercontractGiftPageComponent],
  imports: [CommonModule, SectionHeaderGapModule, SectionTextModule],
})
export class OrdercontractGiftPageModule {}
