<div #wrap>
  <div
    *ngIf="message$ | async as message"
    class="topbar"
  >
    <div
      class="topbar__text"
      [innerHTML]="message"
    ></div>
  </div>
</div>
