import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageTitleModule } from '@studiobuki/web-core/lib/image-title';
import { FormPaymentModule } from '@studiobuki/web-core/lib/form-payment';
import { DialogsModule } from '@studiobuki/web-core/lib/dialogs';
import { FormTestButtonsModule } from '@studiobuki/web-core/lib/form-test-buttons';
import { FormEmailModule } from '@studiobuki/web-core/lib/form-email';
import { FormStripeAddressModule } from '@studiobuki/web-core/lib/form-stripe-address';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { FormGiftSpecialModule } from '../form-gift-special/form-gift-special.module';
import { SectionGiftCheckoutComponent } from './section-gift-checkout.component';
import { FormShipFromModule } from '../form-ship-from/form-ship-from.module';

@NgModule({
  declarations: [SectionGiftCheckoutComponent],
  imports: [
    CommonModule,
    ImageTitleModule,
    FormShipFromModule,
    FormPaymentModule,
    DialogsModule,
    FormTestButtonsModule,
    FormGiftSpecialModule,
    FormEmailModule,
    FormStripeAddressModule,
    ButtonModule,
  ],
  exports: [SectionGiftCheckoutComponent],
})
export class SectionGiftCheckoutModule {}
