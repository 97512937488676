<div
  class="content"
  [formGroup]="formGroup"
>
  <div class="content__input">
    <lib-input
      name="email"
      [label]="'Email Address'"
      placeholder="sample@hellobuki.com"
      formControlName="email"
      type="email"
      [error]="EMAIL_ERROR"
      [theme]="EInputTheme.alt"
    ></lib-input>
  </div>
  <div class="content__input">
    <lib-input
      name="password"
      [label]="'Password'"
      formControlName="password"
      type="password"
      [theme]="EInputTheme.alt"
    ></lib-input>
  </div>
  <div class="content__button">
    <lib-button
      (buttonClick)="onSignInButtonClick()"
      [fullWidth]="true"
      [disabled]="formGroup.invalid"
      >{{ 'Login' }}</lib-button
    >
  </div>
  <div class="content__button">
    <lib-button
      [color]="EButtonColor['accent-2']"
      (buttonClick)="onSignUpButtonClick()"
      [fullWidth]="true"
      >{{ 'Create Account' }}</lib-button
    >
  </div>
  <div class="content__footer">
    <p class="footer-text">
      Forgot password? <a (click)="onResetButtonClick()">Click here!</a>
    </p>
  </div>
</div>
