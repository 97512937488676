import type auth from 'firebase/auth';

export const getCurrentAnonymousUser = (
  Auth: auth.Auth,
): auth.User | undefined => {
  const anonymousUser = Auth.currentUser;

  if (!anonymousUser) {
    alert('Anonymous user was not created, please try again.');
    return undefined;
  }

  if (!anonymousUser.isAnonymous) {
    alert('User is not anonymous, sign-out first.');
    return undefined;
  }

  return anonymousUser;
};
