import { Component, ContentChildren, QueryList } from '@angular/core';
import { SectionSummaryItemComponent } from '../section-summary-item/section-summary-item.component';

@Component({
  selector: 'app-section-summary',
  templateUrl: './section-summary.component.html',
  styleUrls: ['./section-summary.component.scss'],
})
export class SectionSummaryComponent {
  @ContentChildren(SectionSummaryItemComponent)
  items?: QueryList<SectionSummaryItemComponent>;

  // constructor() {}
}
