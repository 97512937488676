import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionFaqModule } from '@studiobuki/web-core/lib/section-faq';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { FaqPageComponent } from './faq-page.component';

@NgModule({
  declarations: [FaqPageComponent],
  imports: [CommonModule, SectionHeaderGapModule, SectionFaqModule],
})
export class FaqPageModule {}
