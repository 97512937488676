import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionFooterBannersComponent } from './section-footer-banners.component';

@NgModule({
  declarations: [SectionFooterBannersComponent],
  imports: [CommonModule],
  exports: [SectionFooterBannersComponent],
})
export class SectionFooterBannersModule {}
