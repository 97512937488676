export const book6SymbolCharacterMap = new Map([
  ['あ', 'あいすをあいするあひる'],
  ['い', 'いぬのいしゃ'],
  ['う', 'うちゅうひこうし'],
  ['え', 'えかきのえりまきとかげ'],
  ['お', 'おうさま'],
  ['か', 'かえる'],
  ['き', 'きょうりゅう'],
  ['く', 'くすぐるくま'],
  ['け', 'けーき'],
  ['こ', 'こーひーをのむこあら'],
  ['さ', 'さがすさんたくろーす'],
  ['し', 'しまうま'],
  ['す', 'すいかずきなすかんく'],
  ['せ', 'せんたくやのせいうち'],
  ['そ', 'そうじやのぞう'],
  ['た', 'たんじょうびのたぬき'],
  ['ち', 'ちぇすがつよいちーたー'],
  ['つ', 'つりをするつる'],
  ['て', 'てじなしのてんぐ'],
  ['と', 'どろぼうのとら'],
  ['な', 'なぐさめるなまけもの'],
  ['に', 'にんきもののにんぎょ'],
  ['ぬ', 'ぬりえがしたいぬー'],
  ['ね', 'ねこになりたいねずみ'],
  ['の', 'のうふののこぎりざめ'],
  ['は', 'はむすたー'],
  ['ひ', 'ぴあにすとのひつじ'],
  ['ふ', 'ふくろう'],
  ['へ', 'へび'],
  ['ほ', 'ほんやのほっきょくぐま'],
  ['ま', 'まじょ'],
  ['み', 'みいらのみーあきゃっと'],
  ['む', 'むぎわらぼうしのむささび'],
  ['め', 'めざめるめがねざる'],
  ['も', 'もぐら'],
  ['や', 'やぎ'],
  ['ゆ', 'ゆきだるま'],
  ['よ', 'よーぐるとのようせい'],
  ['ら', 'らいおん'],
  ['り', 'りすのりーだー'],
  ['る', 'るりちょう'],
  ['れ', 'れっさーぱんだ'],
  ['ろ', 'ろぼっと'],
  ['わ', 'わいんずきなわに'],
  ['ん', 'へんしんぺんぎん'],
]);

export const book6WithSymbolMap = new Map([
  ['あ', 'あくしゅ'],
  ['い', 'いしあたま'],
  ['う', 'うらない'],
  ['え', 'えがお'],
  ['お', 'おやつ'],
  ['か', 'かさぶた'],
  ['き', 'きゅうしょく'],
  ['く', 'くしゃみ'],
  ['け', 'けんだま'],
  ['こ', 'ころっけ'],
  ['さ', 'さいころ'],
  ['し', 'しいたけ'],
  ['す', 'すいえい'],
  ['せ', 'せかい'],
  ['そ', 'そばかす'],
  ['た', 'たつまき'],
  ['ち', 'ちこく'],
  ['つ', 'つみき'],
  ['て', 'てつぼう'],
  ['と', 'とかげ'],
  ['な', 'なぞなぞ'],
  ['に', 'にくじゃが'],
  ['ぬ', 'ぬいぐるみ'],
  ['ね', 'ねぼう'],
  ['の', 'のみもの'],
  ['は', 'はなび'],
  ['ひ', 'ひなたぼっこ'],
  ['ふ', 'ふでばこ'],
  ['へ', 'へいたい'],
  ['ほ', 'ほっぺた'],
  ['ま', 'まゆげ'],
  ['み', 'みみず'],
  ['む', 'むらさき'],
  ['め', 'めぐすり'],
  ['も', 'もみじ'],
  ['や', 'やくそく'],
  ['ゆ', 'ゆうびん'],
  ['よ', 'よりみち'],
  ['ら', 'らくがき'],
  ['り', 'りょうり'],
  ['る', 'るすばん'],
  ['れ', 'れんしゅう'],
  ['ろ', 'ろうそく'],
  ['わ', 'わすれもの'],
]);

export const book6WithOutSymbolMap = new Map([
  ['あ', 'くしゅ'],
  ['い', 'しあたま'],
  ['う', 'らない'],
  ['え', 'がお'],
  ['お', 'やつ'],
  ['か', 'さぶた'],
  ['き', 'ゅうしょく'],
  ['く', 'しゃみ'],
  ['け', 'んだま'],
  ['こ', 'ろっけ'],
  ['さ', 'いころ'],
  ['し', 'いたけ'],
  ['す', 'いえい'],
  ['せ', 'かい'],
  ['そ', 'ばかす'],
  ['た', 'つまき'],
  ['ち', 'こく'],
  ['つ', 'みき'],
  ['て', 'つぼう'],
  ['と', 'かげ'],
  ['な', 'ぞぞ'],
  ['に', 'くじゃが'],
  ['ぬ', 'いぐるみ'],
  ['ね', 'ぼう'],
  ['の', 'みも'],
  ['は', 'なび'],
  ['ひ', 'なたぼっこ'],
  ['ふ', 'でばこ'],
  ['へ', 'いたい'],
  ['ほ', 'っぺた'],
  ['ま', 'ゆげ'],
  ['み', 'ず'],
  ['む', 'らさき'],
  ['め', 'ぐすり'],
  ['も', 'みじ'],
  ['や', 'くそく'],
  ['ゆ', 'うびん'],
  ['よ', 'りみち'],
  ['ら', 'くがき'],
  ['り', 'ょう'],
  ['る', 'すばん'],
  ['れ', 'んしゅう'],
  ['ろ', 'うそく'],
  ['わ', 'すれもの'],
]);
