import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceModule } from '@studiobuki/web-core/lib/price';
import { ProductRadioCardModule } from '@studiobuki/web-core/lib/product-radio-card';
import { SafePipeModule } from 'safe-pipe';
import { BookComponent } from './book.component';
import { PhotoComponent } from './components/photo/photo.component';
import { TickComponent } from './components/tick/tick.component';

@NgModule({
  declarations: [BookComponent, PhotoComponent, TickComponent],
  imports: [CommonModule, PriceModule, ProductRadioCardModule, SafePipeModule],
  exports: [BookComponent],
})
export class BookModule {}
