import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { INPUT_VALUE_COVER_TYPE_SOFT } from '@shared/common/book/constants';
import type { TBookCover, TBookWrapping } from '@shared/jp/book/interfaces';
import { TBookAlias } from '@shared/jp/book/interfaces';
import { IBookNotice } from '@shared/common/data/books/notice/types';
import { TBookPhoto } from '@shared/common/data/books/photos/types';
import type { IBookPrice } from '@shared/jp/data/books/prices/types';
import { TBookPrices } from '@shared/jp/data/books/prices/types';
import { includeShippingToBookPrice } from '@shared/common/data/books/prices/utils';
import type { IBook } from '@shared/jp/data/books/types';
import { IShipping } from '@shared/jp/data/shipping/types';
import {
  getBooksDiscount,
  getShippingDiscount,
} from '@shared/jp/discount/utils';
import { slideY } from '@studiobuki/web-core';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// import { Logger } from '@shared/logger';

// const log = new Logger('SectionGiftBooksComponent -> BookComponent');

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideY()],
})
export class BookComponent {
  @Input() selected = false;

  @Input() alias!: TBookAlias;

  @Input() photo!: TBookPhoto;

  @Input() caption!: IBook['caption'];

  @Input() summary!: IBook['summary'];

  @Input() notice!: IBookNotice;

  @Input() prices!: TBookPrices;

  @Input() shippingIncluded!: IShipping;

  @Input() covers!: IBook['covers'];

  @Output() bookClick = new EventEmitter<void>();

  @Input() set cover(value: TBookCover | null) {
    if (value && this._cover$.value !== value) {
      this._cover$.next(value);
      this.coverChange.emit(value);
    }
  }

  @Output() coverChange = new EventEmitter<TBookCover>();

  @Input() set wrapping(value: TBookWrapping | null) {
    if (value && this._wrapping$.value !== value) {
      this._wrapping$.next(value);
      this.wrappingChange.next(value);
    }
  }

  @Output() wrappingChange = new EventEmitter<TBookWrapping>();

  get minPrice(): IBookPrice {
    const coverPrice = this.prices[0];

    return includeShippingToBookPrice(
      (coverPrice as Exclude<typeof coverPrice, undefined>)[1],
      this.shippingIncluded,
    );
  }

  get coversArr() {
    return Object.values(this.covers);
  }

  private _cover$ = new BehaviorSubject<TBookCover>(
    INPUT_VALUE_COVER_TYPE_SOFT,
  );

  public cover$ = this._cover$.asObservable();

  private _wrapping$ = new BehaviorSubject<TBookWrapping>('standart');

  public wrapping$ = this._wrapping$.asObservable();

  private _hover$ = new BehaviorSubject(false);

  public hover$ = this._hover$.asObservable();

  public discount$ = this._discount.activeDiscountCampaign$.pipe(
    map((_dC) => _dC && getBooksDiscount(_dC, this.alias)),
  );

  public shippingDiscount$ = this._discount.activeDiscountCampaign$.pipe(
    map((_dC) => {
      const shipping = _dC && this.minPrice.shippingIncluded;

      return shipping && getShippingDiscount(_dC, shipping.id);
    }),
  );

  public readonly includeShippingToBookPrice = includeShippingToBookPrice;

  constructor(
    public media: MediaService,
    private _discount: DiscountService,
  ) {}

  public onBookClick(): void {
    this.bookClick.emit();
  }

  public onCoverClick(cover: TBookCover): void {
    this.cover = cover;
  }

  public onWrappingClick(wrapping: TBookWrapping): void {
    this.wrapping = wrapping;
  }

  public onMouseenter(): void {
    this._hover$.next(true);
  }

  public onMouseleave(): void {
    this._hover$.next(false);
  }
}
