import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { BannerOneComponent } from './banner-one.component';
import { InfoComponent } from './components/info/info.component';
import { TimeComponent } from './components/time/time.component';

@NgModule({
  declarations: [BannerOneComponent, InfoComponent, TimeComponent],
  imports: [CommonModule, SectionHeaderGapModule],
  exports: [BannerOneComponent],
})
export class BannerOneModule {}
