<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
  ></app-section-header-gap>
  <lib-section-text
    class="wrap__section-text"
    [articles]="articles"
  ></lib-section-text>
  <!-- <app-section-footer-banner
    class="wrap__section-footer-banner"
  ></app-section-footer-banner> -->
</div>
