<button
  class="link"
  (click)="onButtonClick()"
>
  <svg-icon
    class="link__ico"
    *ngIf="linkSrc"
    [src]="linkSrc"
    [applyClass]="true"
  ></svg-icon>
  <span
    class="link__txt"
    *ngIf="text"
    >{{ text }}</span
  >
</button>
