import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { ReviewsPageComponent } from './reviews-page.component';

@NgModule({
  declarations: [ReviewsPageComponent],
  imports: [CommonModule, SectionHeaderGapModule],
})
export class ReviewsPageModule {}
