<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <lib-image-title type="gift">ギフトコードを使う</lib-image-title>
      </div>
      <div class="container__content">
        <p class="text">受け取ったギフトコードを入力し、</p>
        <p class="text">世界に一冊、その子のためだけの絵本をつくりましょう。</p>
      </div>
    </div>
  </div>
</div>
