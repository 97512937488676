import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ObserversModule } from '@angular/cdk/observers';
import { FormHeaderCheckoutModule } from '@studiobuki/web-core/lib/form-header-checkout';
import { FormEmailModule } from '@studiobuki/web-core/lib/form-email';
import { FormStripeAddressModule } from '@studiobuki/web-core/lib/form-stripe-address';
import { FormPaymentModule } from '@studiobuki/web-core/lib/form-payment';
import { FormTestButtonsModule } from '@studiobuki/web-core/lib/form-test-buttons';
import { SectionMessageDateAltModule } from '@studiobuki/web-core/lib/section-message-date-alt';
import { PriceModule } from '@studiobuki/web-core/lib/price';
import { NavigationModule } from '@studiobuki/web-core/lib/navigation';
import { MainComponent } from './main.component';
import { HeaderComponent } from './components/header/header.component';
import { FormShippingMethodComponent } from './components/form-shipping-method/form-shipping-method.component';
import { ToggleCardsComponent } from './components/toggle-cards/toggle-cards.component';
import { ToggleCardsItemComponent } from './components/toggle-cards-item/toggle-cards-item.component';
import { SectionSummaryComponent } from './components/section-summary/section-summary.component';
import { SectionSummaryItemComponent } from './components/section-summary-item/section-summary-item.component';
import { ToggleSameAddressComponent } from './components/toggle-same-address/toggle-same-address.component';

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    FormShippingMethodComponent,
    ToggleCardsComponent,
    ToggleCardsItemComponent,
    SectionSummaryComponent,
    SectionSummaryItemComponent,
    ToggleSameAddressComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    ObserversModule,
    FormHeaderCheckoutModule,
    FormEmailModule,
    FormStripeAddressModule,
    FormTestButtonsModule,
    FormPaymentModule,
    NavigationModule,
    SectionMessageDateAltModule,
    PriceModule,
  ],
  exports: [MainComponent],
})
export class MainModule {}
