<div
  class="content"
  [formGroup]="formGroup"
>
  <div class="content__input">
    <lib-input
      name="email"
      [label]="'Email Address'"
      placeholder="sample@hellobuki.com"
      formControlName="email"
      type="email"
      [error]="EMAIL_ERROR"
      [theme]="EInputTheme.alt"
    ></lib-input>
  </div>
  <div class="content__input">
    <lib-input
      type="checkbox"
      label="{{ 'Receive newsletters from BÜKI' }}"
      name="emailReceive"
      placeholder="sample@hellobuki.com"
      formControlName="emailReceive"
      [theme]="EInputTheme.alt"
    ></lib-input>
  </div>
  <div class="content__input">
    <lib-input
      name="password"
      [label]="'Password'"
      formControlName="password"
      type="password"
      [error]="REQUIRED_ERROR"
      [theme]="EInputTheme.alt"
    ></lib-input>
  </div>
  <div class="content__input">
    <lib-input
      name="password2"
      label="同じパスワードを入力してください"
      formControlName="password2"
      type="password"
      [error]="PASSWORD_MATCH_ERROR"
      [theme]="EInputTheme.alt"
    ></lib-input>
  </div>
  <div class="content__button">
    <lib-button
      (buttonClick)="onSignUpButtonClick()"
      [fullWidth]="true"
      [disabled]="formGroup.invalid"
      >{{ 'Create Account' }}</lib-button
    >
  </div>
  <div class="content__button">
    <lib-button
      [color]="EButtonColor['accent-2']"
      (buttonClick)="onSignInButtonClick()"
      [fullWidth]="true"
      >{{ 'Login' }}</lib-button
    >
  </div>
</div>
