<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__section container__section_message">
        <p class="message">
          ご注文や絵本については、以下の問い合わせ窓口（運営：STUDIO
          BUKI株式会社）にご連絡をお願いします<br /><a
            [routerLink]="['/', ROUTE_PATH_FAQ]"
            >FAQ（よくある質問）</a
          >も併せてご覧ください
        </p>
      </div>
      <div class="container__section container__section_card">
        <app-block-sns-card></app-block-sns-card>
      </div>
      <div class="container__section container__section_card">
        <app-block-email-phone-card></app-block-email-phone-card>
      </div>
    </div>
  </div>
</div>
