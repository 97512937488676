<app-section-header-gap
  *ngIf="mediaService.isLtXl$ | async"
></app-section-header-gap>
<div class="wrap">
  <div class="wrap__bg">
    <picture>
      <source
        media="(max-width: 767px)"
        srcset="assets/images/banner-one/hero_mobile.jpg"
        width="767px"
      />
      <source
        media="(max-width: 999px)"
        srcset="assets/images/banner-one/hero_tablet.jpg"
        width="1239px"
      />
      <source
        media="(max-width: 1439px)"
        srcset="assets/images/banner-one/hero_tablet.jpg"
        width="1439px"
      />
      <img
        src="assets/images/banner-one/hero.jpg"
        alt="Banner background image."
        width="1920px"
        style="max-width: unset"
      />
    </picture>
  </div>
  <div class="wrap__time">
    <app-time></app-time>
  </div>
  <!-- ABSOLUTE -->
  <div class="wrap__container">
    <div class="container">
      <div class="container__info">
        <app-info></app-info>
      </div>
    </div>
  </div>
</div>
