<div class="container">
  <a
    class="container__item"
    target="_blank"
    href="https://book.froebel-kan.co.jp/ "
  >
    <img
      src="assets/images/banner1.jpg"
      alt="Froebelkan banner."
    />
  </a>
  <a
    class="container__item"
    target="_blank"
    href="https://www.yokohama-anpanman.jp/buy/shop/kmwr34dkduwjmxfi.html "
  >
    <img
      src="assets/images/banner2.jpg"
      alt="Froebelkan banner."
    />
  </a>
  <a
    class="container__item"
    target="_blank"
    href="https://www.froebel-tsubame.jp/ "
  >
    <img
      src="assets/images/banner3.jpg"
      alt="Froebelkan banner."
    />
  </a>
  <a
    class="container__item"
    target="_blank"
    href="https://asoppa.com/ "
  >
    <img
      src="assets/images/banner4.jpg"
      alt="Froebelkan banner."
    />
  </a>
</div>
