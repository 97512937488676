import { Component } from '@angular/core';
import { faqData } from 'src/app/data/faq/data';

@Component({
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent {
  public readonly faqData = faqData;
}
