import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipeModule } from 'safe-pipe';
import { SectionGiftUseCasesComponent } from './section-gift-use-cases.component';
import { UseCaseItemComponent } from './components/use-case-item/use-case-item.component';

@NgModule({
  declarations: [SectionGiftUseCasesComponent, UseCaseItemComponent],
  imports: [CommonModule, SafePipeModule],
  exports: [SectionGiftUseCasesComponent],
})
export class SectionGiftUseCasesModule {}
