import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { ButtonModule } from '@studiobuki/web-core/lib/button';
import { NotFoundPageComponent } from './not-found-page.component';

@NgModule({
  declarations: [NotFoundPageComponent],
  imports: [CommonModule, SectionHeaderGapModule, ButtonModule],
})
export class NotFoundPageModule {}
